import {setCookie} from "./cookies";
import {tracker} from "./tracker";


let local = 'https://socialbluebook.com/';
let base = local + 'django/';
let stripe_pk = 'pk_live_9jxz2NKYYt2fqYHyJCwPY9xY';

// Possible values: dev, stage, prod
switch (process.env.ENV_VAR) {
    case 'stage':
        local = 'https://staging.socialbluebook.com/';
        base = local + 'django/';
        stripe_pk = 'pk_test_pHlqIOLGYH7Jf0ex6qZw1wvy';
        break;
    case 'dev':
        local = 'https://localhost:8001/';
        base = 'https://localhost:8001/';
        stripe_pk = 'pk_test_pHlqIOLGYH7Jf0ex6qZw1wvy';
        break;
}


export {local, base, stripe_pk};

export function baseUrl(endpoint) {
    return `${base}${endpoint}`;
}

export function localUrl(endpoint) {
    return `${local}${endpoint}`;
}

export function imageBaseUrl(endpoint) {
    const media_url = local +'media/';
    return `${media_url}${endpoint}`;
}

export function platform_add_w_redirect(platform, urls, multiplePlatform, redirect){
    if(multiplePlatform){
        tracker("Connecting a Platform",
            {
                'action' : 'Connecting Platforms',
                'modal' : 'connect another platform',
                'platform' : platform,
                'eventCategory': 'AddPlatform',
                'eventAction': 'Connecting a Platform',
            });
    }else{
        if(platform === 'Instagram'){
            tracker("Connecting a Platform",
                {
                    'action' : 'Connecting Platforms',
                    'modal' : 'instagram modal',
                    'platform' : platform,
                    'eventCategory': 'AddPlatform',
                    'eventAction': 'Connecting a Platform',
                });
        }else{
            tracker("Connecting a Platform",
                {
                    'action' : 'Connecting Platforms',
                    'modal' : 'initial platform modal',
                    'platform' : platform,
                    'eventCategory': 'AddPlatform',
                    'eventAction': 'Connecting a Platform',
                });
        }
    }

    switch (platform) {
        case 'Youtube':
        case 'Blog':
            if(redirect){
                setCookie('SBB_REDIRECT_STATE', 3, '24');
            }
            window.location = base.slice(0, -1) + urls[platform];
            break;
        case 'Instagram':
        case 'Twitter':
        case 'Facebook':
        case 'Twitch':
        case 'TikTok':
        case 'Tiktok':
            if(redirect){
                setCookie('SBB_REDIRECT_STATE', 3, '24');
            }
            window.location = urls[platform];
            break;
        default:
            break;
    }
}


export const transformRequest = (data) => {
    let fd = data ? new FormData() : null;
    if (data) {
        let keys = Object.keys(data);
        keys.map(function (key) {
            let value = data[key];
            // Is it a file?
            if ((value instanceof File || value instanceof Blob) && value.size <10000000) {
                fd.append(key, value, value.name);
            } else if (typeof value === 'object') {
                // Is it an object?
                if(value === null){
                    fd.append(key, value);
                }else{
                    fd.append(key, JSON.stringify(value));
                }
            } else {
                fd.append(key, value);
            }
        });
    }
    return fd;
};

function forEachSorted(obj, iterator, context) {
    let keys = Object.keys(obj).sort();
    for(let key of keys){
        iterator.call(context, obj[key], key);
    }
    return keys;
}
export function isFunction(value) { return typeof value === 'function'; }
export function isObject(value) { return value !== null && typeof value === 'object'; }
export function isUndefined(value) { return typeof value === 'undefined'; }
export function isNumber(value) {return typeof value === 'number';}
export function isArray(arr) {
    return Array.isArray(arr) || arr instanceof Array;
}
export function isDate(value) {
    return toString.call(value) === '[object Date]';
}
export function isWindow(obj) {
    return obj && obj.window === obj;
}
export function isUrl(url){
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return regex.test(url);
}

function encodeUriQuery(val, pctEncodeSpaces) {
    return encodeURIComponent(val).
    replace(/%40/gi, '@').
    replace(/%3A/gi, ':').
    replace(/%24/g, '$').
    replace(/%2C/gi, ',').
    replace(/%3B/gi, ';').
    replace(/%20/g, (pctEncodeSpaces ? '%20' : '+'));
}

function toJsonReplacer(key, value) {
    let val = value;

    if (typeof key === 'string' && key.charAt(0) === '$' && key.charAt(1) === '$') {
        val = undefined;
    } else if (isWindow(value)) {
        val = '$WINDOW';
    } else if (value &&  window.document === value) {
        val = '$DOCUMENT';
    }

    return val;
}
function toJson(obj, pretty) {
    if (isUndefined(obj)) return undefined;
    if (!isNumber(pretty)) {
        pretty = pretty ? 2 : null;
    }
    return JSON.stringify(obj, toJsonReplacer, pretty);
}

function serializeValue(v) {
    if (isObject(v)) {
        return isDate(v) ? v.toISOString() : toJson(v);
    }
    return v;
}
export const httpParamSerializer = data => {
    if (!data) return '';
    let parts = [];
    forEachSorted(data, function(value, key) {
        if (value === null || isUndefined(value) || isFunction(value)) return;

        if (isArray(value)) {
            value.map(v => {
                parts.push(encodeUriQuery(key)  + '=' + encodeUriQuery(serializeValue(v)));
            })
        } else {
            parts.push(encodeUriQuery(key) + '=' + encodeUriQuery(serializeValue(value)));
        }
    });
    return parts.join('&');
};
export const GET_COURSES = 'GET_COURSES';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_ERROR = 'GET_COURSES_ERROR';

export const getCourses = () => {
    return {
        type: GET_COURSES,
    }
};

export default function(state = [], action) {
    const response = action.response;
    switch(action.type) {
        case GET_COURSES_SUCCESS:
            return { ...state, response };
        case GET_COURSES_ERROR:
            return { ...state, response };
        default:
            return state;
    }
}


import "core-js/stable";
import "regenerator-runtime/runtime";
import {call, put} from "redux-saga/effects";
import * as auth_types from "./reducer";
import {loginUserService, logoutUserService} from "./requests";

export function* loginSaga(payload) {
    try {
        const response = yield call(loginUserService, payload);
        if(response.status === 200){
            yield put({type: auth_types.LOGIN_USER_SUCCESS, response});
        }else{
            yield put({type: auth_types.LOGIN_USER_ERROR, response});
        }

    } catch (error) {
        yield put({type: auth_types.LOGIN_USER_ERROR, error})
    }
}

export function* logoutSaga(payload) {
    try {
        const response = yield call(logoutUserService, payload);
        yield put({type: auth_types.LOGOUT_USER_SUCCESS, response});
    } catch (error) {
        yield put({type: auth_types.LOGOUT_USER_ERROR, error})
    }
}


import axios from "axios";
import {baseUrl} from "../../utils/baseurl";

export const loginUserService = (request) => {
    const LOGIN_ENDPOINT = baseUrl('login/');
    const config = {
        method: 'POST',
        url: LOGIN_ENDPOINT,
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        withXSRFToken: true,
        data: request.user
    };
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error.response;
        });
};

export const logoutUserService = () => {
    const LOGOUT_ENDPOINT = baseUrl('logout/');
    const config = {
        method: 'POST',
        url: LOGOUT_ENDPOINT,
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        withXSRFToken: true,
    };
    return axios.request(config)
        .then(response => {
            return response.data;
        })
        .then(json => {
            return json;
        }).catch(error => {
            return error;
        });
};

export const forgotPasswordUserService = (data) => {
    const RESET_ENDPOINT = baseUrl('reset_password/');
    const config = {
        method: 'POST',
        url: RESET_ENDPOINT,
        headers: {
            'content-type': 'application/json',
        },
        data: data
    };
    return axios.request(config);
};

export const confirmPasswordUserService = (identification, data) => {
    const identification_url = 'password_confirm/' + identification.uid + '/' + identification.token + '/';
    const PASSWORD_CONFIRM_ENDPOINT = baseUrl(identification_url);
    const config = {
        method: 'POST',
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        withXSRFToken: true,
        url: PASSWORD_CONFIRM_ENDPOINT,
        headers: {
            'content-type': 'application/json',
        },
        data: data
    };

    return axios.request(config);
};

import React from "react"
import './pages.scss';

class Page extends React.Component {
    componentDidMount() {
        document.title = 'Social Bluebook - ' + this.props.title;
        window.scrollTo(0,0);
    }
    render() {
        const PageComponent = this.props.component;
        const props = this.props;
        return (
            <PageComponent {...props} />
        )
    }
}
export default Page;

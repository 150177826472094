export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';

export const getUserAction = () => {
    return {
        type: GET_USER,
    }
};

export const getUserProfileAction = () => {
    return {
        type: GET_USER_PROFILE,
    }
};

export default function(state = [], action) {
    const response = action.response;
    switch(action.type) {
        case GET_USER_ERROR:

            return { ...state, response };
        case GET_USER_SUCCESS:
            return { ...state, response };
        default:
            return state;
    }
}

export function profile(state = [], action) {
    const response = action.response;
    switch(action.type) {
        case GET_USER_PROFILE_ERROR:
            return { ...state, response };
        case GET_USER_PROFILE_SUCCESS:
            return { ...state, response };
        default:
            return state;
    }
}

import React, {Suspense, useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {hasUser} from "../../utils/cookies";
import Page from "../page/page";
import {getUser} from "../../utils/user";
import Grid from "@mui/material/Grid";
import {SBBCircularBlueLoader} from "../common/loader/circularLoader";
import NotFound from "../../pages/notFound/notFound";

const renderLoaderBlue = () => <Grid container item alignItems="center" justifyContent="center" sx={{pt:"100px", mt: '50px'}}>
                                    <SBBCircularBlueLoader />
                                </Grid>;

const PrivateRoute = (MainProps) => {
    const logged_condition = hasUser();
    const role_permission = (MainProps.permittedRoles && MainProps.permittedRoles.indexOf(MainProps.userRole) === -1);

    if(logged_condition){
        if(role_permission){
            // user role not permitted to current path
            return <NotFound />
        }else{
            //check if user has payment mode and valid subscriptions:
            return <Suspense fallback={renderLoaderBlue()}>
                <Page {...MainProps}/>
            </Suspense>
        }
    }else{
        return <Navigate  to="/login" />
    }
};
export  default  PrivateRoute;

import React, {useEffect} from 'react'
import Container from "@mui/material/Container";
import {useNavigate, useLocation} from "react-router";
import Grid from "@mui/material/Grid";
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import LogoImage from "../../images/sbb-logo-blue.svg";

import './404.scss';

const NotFound  = (props) => {
        const location = useLocation();
        const navigate = useNavigate();

        useEffect(()=>{
            return () => {
            }
        },[]);

        const goBack = () => {
            navigate(-1);
        };

        const goToRoot = () => {
               window.location = window.location.origin;
        };

        return <div className='main-container d-404'>
                <Container maxWidth="xl">
                       <Grid justifyContent="center" alignItems="center" container>
                              <Grid item>
                                      <div className="text-center">
                                          <img width="300px"
                                               height="auto"
                                               className="hover"
                                               onClick={goToRoot}
                                               style={{
                                                   marginBottom: '150px',
                                                   marginTop: '-75px'
                                               }}
                                               src={LogoImage}/>
                                      </div>
                                      <div className="text-center">
                                              <SentimentDissatisfiedOutlinedIcon className="color-blue" sx={{
                                                      fontSize: '160px'
                                              }}/>
                                      </div>
                                      <h1 className="text-center top-header">
                                              404 - Not Found
                                      </h1>
                                      <p className="text-center">We couldn't find <i>{location.pathname}</i></p>
                                      <p className="text-center">
                                           <a title="Go Back" className="hover" onClick={goBack}>
                                                <strong>Go Back</strong>
                                           </a> or head over to <a title="Social BlueBook" className="hover" onClick={goToRoot}>
                                                <strong>Social BlueBook</strong>
                                           </a> to choose a new direction
                                      </p>
                              </Grid>
                       </Grid>
                </Container>
            </div>
};

export default NotFound;

import React from 'react';
import Grid from "@mui/material/Grid";
import './footerComponent.scss';
import {Link} from "react-router-dom";
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import {social_icons} from "../../utils/constants";

const FooterComponent = () => {
    return <Grid container direction="column" className="footer-container">
        <Grid container item xs={12}>
            <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
                <h3>
                    Company
                </h3>
                <ul role="list" className="footer-list">
                    <li>
                        <Link to={'/about-us'}>
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link to={'/home'} state={{
                            goToParam: "pricing",
                        }}>
                            Pricing
                        </Link>
                    </li>
                    <li>
                            <a
                            href="mailto:support@socialbluebook.com?subject=I%20want%20to%20work%20with%20creators%20on%20Social%20Bluebook">
                                Brand or Agency?</a>
                    </li>
                    <li>
                            <a
                            href="mailto:support@socialbluebook.com?subject=I%20need%20help%20from%20Social%20Bluebook">Help</a>
                    </li>
                </ul>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
                <h3>
                    Legal
                </h3>
                <ul role="list" className="footer-list">
                    <li>
                        <Link to={'/terms-of-use'}>
                            Terms of Service
                        </Link>
                    </li>
                    <li>
                        <Link to={'/privacy-policy'}>
                            Privacy Policy
                        </Link>
                    </li>
                </ul>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                <h3>
                    Stay Connected
                </h3>
                <ul role="list" className="footer-list social">
                    <li>
                        <Link to={'https://www.youtube.com/@Socialbluebook'} target="_blank" class={'branded-youtube'}>
                            {social_icons.youtube} YouTube
                        </Link>
                    </li>
                    <li>
                        <Link to={'https://www.instagram.com/socialbluebook/'} target="_blank" class={'branded-instagram'}>
                            {social_icons.instagram} Instagram
                        </Link>
                    </li>
                    <li>
                        <Link to={'https://www.tiktok.com/@socialbluebook'} target="_blank" class={'branded-tiktok'}>
                            {social_icons.tiktok} TikTok
                        </Link>
                    </li>
                    <li>
                        <Link to={'https://twitter.com/SocialBluebook'} target="_blank" class={'branded-tiktok'}>
                            {social_icons.twitter} X
                        </Link>
                    </li>
                </ul>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <p className="text-dark">
                © {(new Date().getFullYear())} New Media Trader, Inc. All Rights Reserved.
            </p>
        </Grid>
    </Grid>
}

export default FooterComponent;
import { combineReducers } from 'redux';
import login from './auth/reducer';
import user, {profile} from './user/reducer';
import dashboard from './dashboardCards/reducer';
import proTips from './proTips/reducer';
import courses from './academy/reducer';
import blog from './blog/reducer';
import deals from './brandDeals/reducer';
import talents from './talent/reducer';

const rootReducer = combineReducers({
    login,
    user,
    dashboard,
    proTips,
    courses,
    blog,
    deals,
    talents,
    profile,
});

export default rootReducer;

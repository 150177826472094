import axios from "axios";
import {baseUrl} from "../../utils/baseurl";

export const getCourses = () => {
    const GET_COURSES_ENDPOINT = baseUrl('latest-courses/');
    const config = {
        method: 'GET',
        url: GET_COURSES_ENDPOINT,
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        withXSRFToken: true,
    };
    return axios.request(config)
        .then((response) => {
            return response;
        }).catch((error) => {
            return error;
        });
};

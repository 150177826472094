export const SBB_DEAL_TRACKER_TOUR = [
    {
        target: '[data-tour="adding-a-deal"]',
        content: "Clicking 'Add a deal' allows you to create an open opportunity for a brand that you are working with.  You'll be able to add key details about the opportunity.",
        title: 'Adding a Deal',
        disableBeacon: true,
        placement: "left",
        next_location: '/add-deal',
    },
    {
        target: '[data-tour="deal-details"]',
        title: "Deal Details",
        content: 'Get started with adding a deal by entering the brand\'s name, today\'s date, and the current status of the opportunity.',
        disableBeacon: true,
        placement: "bottom",
        prev_location: '/deal-manager',
        topOffset: 35,
    },
    {
        target: '[data-tour="deal-platform-deliverables"]',
        title: "Platforms & Deliverables",
        content: 'Click \'Add Platform\' to enter what you are responsible to do for the brand in this opportunity (i.e. YouTube brand/product mention)',
        disableBeacon: true,
        placement: "right",
    },
    {
        target: '[data-tour="deal-documents"]',
        title: "Documents",
        content: 'Upload important documents such as campaign briefs, contract templates, and invoices.',
        disableBeacon: true,
        placement: "right",
        next_location: '/deal-manager',
    },
];

export const SBB_MEDIA_KIT_TOUR = [
    {
        target: '[data-media-kit="edit-media-kit"]',
        content: "Clicking 'Edit' allows you to update all of the information you provided when creating your Media Kit.",
        title: 'Edit',
        disableBeacon: true,
        placement: "bottom",
        topOffset: -85,
    },
    {
        target: '[data-media-kit="preview-media-kit"]',
        title: "Preview",
        content: 'Clicking \'Preview\' allows you to preview your Media Kit so that you can see what it looks like when you send it to a brand.',
        disableBeacon: true,
        placement: "bottom",
        topOffset: -85,
    },
    {
        target: '[data-media-kit="share-media-kit"]',
        title: "Share",
        content: 'Clicking \'Share\' provides you the unique URL to your Media Kit that you can share with any brand your working with. The recipient of your link does NOT have to be a Social Bluebook member to view your Media Kit.',
        disableBeacon: true,
        placement: "bottom",
        topOffset: -85,
    },
    {
        target: '[data-media-kit="contact-me-media-kit"]',
        title: "Contact me",
        content: 'The \'Contact Me\' button is meant for a brand to click on so that they can provide additional information about how they would like to work with you. You will receive an email with their responses.',
        disableBeacon: true,
        placement: "left",
    },
    {
        target: '[data-media-kit="overall-media-kit"]',
        title: "Overall Reach",
        content: '',
        disableBeacon: true,
        placement: "left",
    }
]

export const SBB_VALUATION_TOUR = [
    {
        target: '[data-valuation="price-suggestions"]',
        content: "Social Bluebook calculates different price suggestions based upon the type of brand integration that you do.  Each suggestion offers the base rate plus a High & Low range.",
        title: 'Your Rate Suggestions',
        disableBeacon: true,
        placement: "left",
    },
    {
        target: '[data-valuation="valuation-metrics"]',
        title: "Primary valuation metrics",
        content: 'Our valuation formulas utilize many different important metrics and data points in how we calculate your rate suggestions.  These are a few of the more important ones.',
        disableBeacon: true,
        placement: "bottom",
        topOffset: 35,
    },
    {
        target: '[data-valuation="valuation-refresh"]',
        title: "Refresh your data",
        content: 'Your suggested prices can change all the time considering you\'re always growing as a creator.  A best practice is to refresh your data at least once a week so that you\'re metrics and price suggestions always stay up-to-date.',
        disableBeacon: true,
        placement: "right",
    },
    {
        target: '[data-valuation="valuation-account-removal"]',
        title: "Removing an account",
        content: 'If you ever have a need to remove one of your social accounts from your Valuations page then click this button and select \'Remove Platform.\'  Don\'t worry you can always re-add the account again.',
        disableBeacon: true,
        placement: "right",
    },
    {
        target: '[data-valuation="valuation-insights-view"]',
        title: "View Insights",
        content: 'A few of platforms that we integrate with (i.e. YouTube & Instagram) provide a lot more data than others. Here you can see additional data such as customer demographics and other metrics.  This is not available on certain platforms such as TikTok.',
        disableBeacon: true,
        placement: "right",
    },
];

export const SBB_DASHBOARD_TOUR = [
    {
        target: '[data-tour="profile-status"]',
        content: "This is your profile completion bar. It's going to take you through the 6 steps to becoming an SBB power user. All power users earn a free month of SBB by completing all the steps.",
        title: 'Profile Completion',
        disableBeacon: true,
        placement: "bottom",
    },
    {
        target: '[data-tour="upcoming-events"]',
        content: "Don't miss any of our amazing live trainings happening in our discord community every week.",
        title: 'Upcoming Events',
        disableBeacon: true,
        placement: "left",
    },
    {
        target: '[data-tour="discord-latest"]',
        content: "See what conversations are happening right now in our discord community.",
        title: 'Latest from SBB Discord',
        disableBeacon: true,
        placement: "left",
    },
    {
        target: '[data-tour="valuations-dash"]',
        content: "Our valuations tool will help you understand how much you could be charging brands when doing sponsorships. Get started by connecting your accounts.",
        title: 'See your Value',
        disableBeacon: true,
        placement: "left",
    },
    {
        target: '[data-tour="contact-dash"]',
        content: "If you need anything at all just hit this button and send us a message at support@socialbluebook.com. We're standing by to help!",
        title: 'Contact Support',
        disableBeacon: true,
        placement: "left",
    },
]

export const tours_locations = {
    'deal-manager': SBB_DEAL_TRACKER_TOUR,
    'add-deal': SBB_DEAL_TRACKER_TOUR,
    'edit-deal': SBB_DEAL_TRACKER_TOUR,
    'my-media-kit': SBB_MEDIA_KIT_TOUR,
    'valuations': SBB_VALUATION_TOUR,
    'dashboard': SBB_DASHBOARD_TOUR,
}

export const getHideTutorialKey = (tourKey) => {
    return 'hide-' + tourKey;
}
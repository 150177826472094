import { useSnackbar } from 'notistack';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import React, {Fragment, useEffect, useState} from "react";

const useNotification = () => {
    const [conf, setConf] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const action = key => (
        <Fragment>
            <IconButton onClick={() => { closeSnackbar(key) }} style={{color:'white'}}>
                <CloseIcon />
            </IconButton>
        </Fragment>
    );

    useEffect(()=>{
        if(conf?.msg){
            let variant = 'default';
            if(conf.variant){
                variant = conf.variant;
            }
            let autoHideDuration = 5000;
            if(conf.autoHideDuration){
                autoHideDuration = conf.autoHideDuration;
            }
            enqueueSnackbar(conf.msg, {
                variant: variant,
                autoHideDuration: autoHideDuration,
                preventDuplicate: true,
                action
            });
        }
    },[conf]);
    return [conf, setConf];
};
export default useNotification;

import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import LogoImage from "../../images/sbb-logo-white.svg";
import './defaultMenu.scss';
import variables from "../../css/theme.module.scss";
import Button from "@mui/material/Button/Button";
import {v4 as uuidv4} from "uuid";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from '@mui/icons-material/Menu';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import {ClickAwayListener} from "@mui/material";
import {hasUser} from "../../utils/cookies";
import {useNavigate} from "react-router";

const login =  {
    name: 'Log In',
    action: '/login',
    type: 'navigate'
}
const get_started = {
    name: 'Get Started',
    action: '/get-started',
    type: 'navigate'
}
const dashboard = {
    name: 'Dashboard',
    action: '/dashboard',
    type: 'navigate'
}


const DefaultMenu = () => {
    let loggedIn = !!hasUser();
    const navigate = useNavigate();
    const medium = useMediaQuery((theme) => theme.breakpoints.between('xs', '990'));
    const [openedMenu, setOpenedMenu] = useState(false)
    const [menuList, setMenuList] = useState([
        {
            name: 'About Us',
            action: '/about-us',
            type: 'nav'
        },
        {
            name: 'Pricing',
            action: '/home',
            type: 'nav',
            state: {
                goToParam: "pricing",
            }
        },
        {
            name: 'Brand or Agency?',
            action: 'mailto:support@socialbluebook.com?subject=I%20want%20to%20work%20with%20creators%20on%20Social%20Bluebook',
            type: 'link'
        },
    ])

    const goHome = () => {
        navigate('/')
    };

    const closeMenu = () => {
        setOpenedMenu(false);
    }

    const toggleMenu = () => {
        setOpenedMenu(!openedMenu);
    }

    useEffect(() => {
        closeMenu()
    }, [medium]);


    useEffect(()=>{
        loggedIn = !!hasUser();
        const MENUS = [...menuList];
        if(loggedIn){
            MENUS.push(dashboard)
        }else{
            MENUS.push(login);
            MENUS.push(get_started);
        }
        setMenuList(MENUS);
    },[]);

    useEffect(()=>{
        console.log(menuList)
    },[menuList]);

    const navigateAction = (element) => {
        if(element.type == 'link'){
            window.open(element.action, '_blank');
        }else{
            if('state' in element){
                navigate(element.action, {state: element.state});
            }else{
                navigate(element.action);
            }

        }
        closeMenu();
    }

    return <>
        <Grid container
              direction='row'
              className="menu-holder">
            <Grid container
                  sx={{p:'10px'}}
                  item
                  direction="row"
                  justifyContent={
                      {
                          xs: 'left',
                          sm: 'left',
                          md: 'left',
                          lg: 'center',
                      }
                  }
                  alignItems="center"
                  xs spacing={0} sm md={3} lg={3}>
                <img width="200px" height="40px" className="hover" onClick={goHome} src={LogoImage}/>
            </Grid>
            {!medium && <Grid container
                              item
                              display={{ xs: "none", sm: "none", md: "flex" }}
                              spacing={0} md={2} lg={2} xl={2}/>}
            {!medium &&
                <Grid container
                      item
                      xs={12} spacing={0} sm md lg
                      justifyContent="space-evenly"
                      alignItems="center">
                    {menuList.map(element => {
                        return <Button color="white"
                                       onClick={()=>{navigateAction(element)}}
                                       key={uuidv4()}
                                       style={{fontFamily: variables.Avenir,}}>
                            {element.name}
                        </Button>
                    })}
                </Grid>
            }
        </Grid>
        {medium &&
            <ClickAwayListener onClickAway={closeMenu}>
                <Grid container
                      item
                      xs={'auto'} sm={'auto'} md={'auto'}
                      justifyContent="end"
                      alignItems="center">
                    <Button color="white"
                            className='mobile-trigger'
                            onClick={toggleMenu}
                            key={uuidv4()}>
                        <MenuIcon fontSize={'large'} />
                    </Button>
                    <Grid className='mobile-menu'>
                        <Accordion expanded={openedMenu}>
                            <AccordionSummary sx={{minHeight: 0}}></AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction='column'>
                                    {menuList.map(element => {
                                        return <Grid item  key={uuidv4()}>
                                            <Button
                                                fullWidth
                                                onClick={()=>{navigateAction(element)}}
                                                style={{fontFamily: variables.Avenir, justifyContent: 'start'}}>
                                                {element.name}
                                            </Button>
                                        </Grid>
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </ClickAwayListener>
        }
    </>
}

export default DefaultMenu;
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `#2715ff`,
	"dark": `#1d1671`,
	"blue": `#003bff`,
	"lightBlue": `#bfbbdb`,
	"darkBlue": `#6e22ff`,
	"secondary": `#deff00`,
	"secondaryDark": `#b6d700`,
	"success": `#3fff3b`,
	"light": `#00e5ff`,
	"violet": `#ff0bd0`,
	"violetDark": `#e10bb2`,
	"lightBackground": `#e7e4f8`,
	"lighterBackground": `#e7e6ee`,
	"lightestBackground": `#ecf0ff`,
	"darkBackground": `#242e42`,
	"secondaryDarkestBlue": `#0e2155`,
	"avenirRoman": `"AvenirRoman","sans-serif"`,
	"avenir": `"Avenir","sans-serif"`,
	"avenirLight": `"AvenirLight","sans-serif"`,
	"avenirBook": `"AvenirBook","sans-serif"`,
	"avenirMedium": `"AvenirMedium","sans-serif"`,
	"avenirMediumOblique": `"AvenirMediumOblique","sans-serif"`,
	"avenirHeavy": `"AvenirHeavy","sans-serif"`,
	"avenirHeavyOblique": `"AvenirHeavyOblique","sans-serif"`,
	"gothamBlack": `"GothamBlack","sans-serif"`,
	"gotham": `"Gotham","sans-serif"`,
	"gothamProMedium": `"GothamProMedium","sans-serif"`,
	"mainFont": `"AvenirMedium","sans-serif"`,
	"thinFont": `"AvenirLight","sans-serif"`,
	"lightFont": `300`,
	"regular": `400`,
	"medium": `500`,
	"bold": `700`,
	"black": `900`,
	"secondaryDarkblue": `#173384`,
	"secondaryLightBackground": `#1035aa`,
	"grey": `#707784`,
	"lightGray": `#e3e4e6`,
	"orange": `#f4bc03`,
	"whiteLight": `#f2f5ff`,
	"textLightest": `#c8cacf`,
	"textLightest2": `#e3e4e6`,
	"textLight": `#707784`,
	"textDefault": `#353d50`,
	"textDark": `#242e42`,
	"backgroundLigther": `#f8f9ff`,
	"primaryDark": `#1d10bf`,
	"primaryLighter": `#6689ff`,
	"accent2Dark": `#2fbf2c`,
	"accentDark": `#efbb00`,
	"statusWarning": `#f4b63c`,
	"secondaryAccent": `#3fff3b`
};
module.exports = ___CSS_LOADER_EXPORT___;

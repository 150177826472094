import "core-js/stable";
import "regenerator-runtime/runtime";
import {call, put} from "redux-saga/effects";
import * as api_types from "./reducer";
import {getProTips} from "./requests";

export function* getProTipsSaga() {
    try {
        const response = yield call(getProTips);
        if(response.status === 200){
            yield put({type: api_types.GET_PRO_TIPS_SUCCESS, response});
        }else{
            yield put({type: api_types.GET_PRO_TIPS_ERROR, response});
        }
    } catch (error) {
        yield put({type: api_types.GET_PRO_TIPS_ERROR, error})
    }
}


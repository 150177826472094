import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Stack, styled } from '@mui/material';
import tabithathewholistic from "../../images/home/tabithathewholistic.png";
import sixmonthslater from "../../images/home/Six Months Later.jpg";
import bethandcourt from "../../images/home/Life with Beth and Court.jpg";
import aaron from "../../images/home/Unnecessary Bluntness Sports Talk.jpg";
import {v4 as uuidv4} from "uuid";

const MobileStepper = ({ activeStep, steps, onStepClick }) => (
    <StepsContainer>
        {Array.from(Array(steps).keys()).map(step => (
            <Step key={step} onClick={() => onStepClick(step)} active={activeStep === step} />
        ))}
    </StepsContainer>
);

const StepsContainer = styled(Stack)(({ theme }) => ({
    backgroundColor: 'transparent',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    padding: theme.spacing(1),
}));

const Step = styled('button')(({ theme, active }) => ({
    height: '8px',
    width: '8px',
    borderRadius: '50%',
    backgroundColor: active ? '#222' : 'rgba(34, 34, 34, .4)',
    cursor: 'pointer',
    margin: theme.spacing(1),
    padding: 0,
    border: 0,
}));

const AutoPlaySwipeableViews = SwipeableViews;


const images = [
    {
        quote: "I've closed at least 4 paid brand deals so far this year, and one was my first 4-figure deal!",
        author: 'Tabitha Howard',
        social: 'tabithathewholistic',
        image: tabithathewholistic,
    },
    {
        quote: "We took what we learned, applied it the next day and landed an $1,800 brand deal.",
        author: 'Beth & Court',
        social: 'Life with Beth and Court',
        image: bethandcourt,
    },
    {
        quote: "I've successfully landed a brand deal from a cold email I sent based on the strategies you went over. This week I successfully upsold a brand, doubling the money I would have gotten.",
        author: 'Josh Teder',
        social: 'Six Months Later',
        image: sixmonthslater,
    },
    {
        quote: "Two brand agencies reached out for small deals. Even had the courage to counter one offer with a 25% higher figure. Was shocked that the Company came back with even higher than that. Hoping to keep the deals rolling in!",
        author: 'Aaron',
        social: 'Unnecessary Bluntness Sports Talk',
        image: aaron,
    },
];

function CarouselSwipableSection() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ maxWidth: 940, flexGrow: 1, width: "100%" }}>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((step, index) => (
                    <div key={uuidv4()}>
                        <div className="testimonial-slide">
                            <div className="testimonial-content">
                                <div className="testimonial-quote"><em>"{step.quote}"</em></div>
                                <div className="testimonial-info">
                                    <div>
                                        <div className="testimonial-author-name">{step.author}</div>
                                        <div>{step.social}</div>
                                    </div>
                                </div>
                            </div>
                            <img
                                src={step.image}
                                loading="lazy"
                                alt={step.author}
                                className="testimonial-image" />
                        </div>
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper steps={maxSteps} activeStep={activeStep} onStepClick={currentStep => setActiveStep(currentStep)} />
        </Box>
    );
}

export default CarouselSwipableSection;
import React, {useState} from 'react';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import Container from "@mui/material/Container";
import useClasses from "../../utils/themeUtils";
import LogoImage from "../../images/sbb-logo-white.svg";
import {resendConfirmEmail} from "../../redux/user/requests";
import useNotification from "../../components/notification/notification";
import {FreeSBBCircularBlueLoader} from "../../components/common/loader/circularLoader";
import styled from "@emotion/styled";
import {Link} from "react-router-dom";
import variables from "../../css/theme.module.scss";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    loginForm: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
});

const SBButton = styled(Link)({
    color: 'white',
    textDecoration: 'none',
    marginRight: '10px',
    '& .MuiButton-root':{
        fontFamily: variables.Avenir,
        letterSpacing: 0
    },
    '&.accent':{
        color: variables.secondary
    }
});

export default function EmailConfirmationWall() {
    const classes = useClasses(useStyles);
    const [, sendNotification] = useNotification();
    const [loading, setLoading] = useState(false);

    const resendEmail = () => {
        setLoading(true);
        resendConfirmEmail().then(r => {
            if(r.data['success']){
                sendNotification({msg: 'Email sent!', variant: 'info'});
            }else{
                sendNotification({msg: 'An error occurred, please try again later or contact system admin', variant: 'info'});
            }
        }).catch(_ => {
            sendNotification({msg: 'An error occurred, please try again later or contact system admin', variant: 'info'});
        }).finally(_ => {
            setLoading(false);
        })
    }

    return <div className='login-container background-dark'>
        <Container maxWidth="md" sx={{p:0}}>
            {loading && <div className="loader-background" style={{
                margin: 0,
                borderRadius: 0,
                padding: 0,
                left: 0,
            }}>
                <FreeSBBCircularBlueLoader />
            </div>}

            <Grid container spacing={0} className="padding-bottom-30" flexWrap="nowrap">
                <Grid container
                      sx={{p:'10px'}}
                      item
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      xs={12} spacing={0} sm={12} md={3} lg={3}>
                    <img width="200px" height="40px" className="hover" src={LogoImage}/>
                </Grid>
                <Grid container
                      item
                      style={{flexGrow: 2}}
                      spacing={0}
                      md lg xl sm xs/>
                <Grid container
                      item
                      style={{flexGrow: 1}}
                      spacing={0}
                      xs sm md lg
                      justifyContent="space-around"
                      alignItems="center">
                    <SBButton to='/logout'>
                        <Button color="inherit" sx={{whiteSpace: 'nowrap'}}>Log Out</Button>
                    </SBButton>
                </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.loginForm}
                  mt={10}
                  justifyContent="center"
                  alignItems="center">

            </Grid>
            <Grid container spacing={0} className={classes.loginForm}
                  mt={3}
                  justifyContent="center"
                  alignItems="center">
                <h1 className="color-white"><small>Please verify your email.</small></h1>
            </Grid>
            <Grid container spacing={0} className={classes.loginForm}
                  mt={3}
                  justifyContent="center"
                  alignItems="center">
                <Grid xs={6} sm={4} md={4} lg={3} xl={3}>
                    <Button fullWidth variant="contained" color="white" onClick={resendEmail}>Resend</Button>
                </Grid>
            </Grid>
        </Container>
    </div>
}
import React, {Component, useEffect, useState} from "react";

//utils:
import clsx from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Link, NavLink} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";

//components:
import Toolbar from '@mui/material/Toolbar';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import styled from "@emotion/styled";
import useTheme from '@mui/material/styles/useTheme';
import CssBaseline from '@mui/material/CssBaseline';
import {Icon} from '@mui/material';
import MenuList from '@mui/material/MenuList';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';

import IconButton from '@mui/material/IconButton';
//icons:
import {ReactComponent as AccountIcon} from "../../icons/account-white.svg";
import {ReactComponent as BrandOfferIcon} from "../../icons/brand-offer-2.svg";
import {ReactComponent as NewIcon} from "../../icons/new.svg";
import {ReactComponent as DashboardIcon} from "../../icons/dashboard-white.svg";
import {ReactComponent as ValuationsIcon} from "../../icons/valuations.svg";
import {ReactComponent as ExternalIcon} from "../../icons/external_icon.svg";
import {ReactComponent as MediaKitIcon} from "../../icons/media-kit-white.svg";
import {ReactComponent as CoursesIcon} from "../../icons/sbb-courses-white.svg";
import {ReactComponent as LogoImage} from "../../images/sbb-logo-white.svg";
import {ReactComponent as MenuIconOpen} from "../../icons/menu_icon_open.svg";
import {ReactComponent as MenuIconMobile} from "../../icons/menu_icon_mobile.svg";
import {ReactComponent as PartnerIcon} from "../../icons/partner-icon.svg";

import CalculateIcon from '@mui/icons-material/Calculate';
import CloseIcon from '@mui/icons-material/Close';

import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import EventIcon from '@mui/icons-material/Event';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import BookIcon from '@mui/icons-material/Book';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PersonIcon from '@mui/icons-material/PersonOutline';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

//menu constants and custom components:
import {menuStructure, menuStructureAdv, pages, roles, user_menu} from "../../utils/constants";
import {getFullName, getProfilePicture, getUser, getUserMenuException, hasActiveSubscription} from "../../utils/user";
import variables from './../../css/theme.module.scss';
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {ReactComponent as LightIcon} from "../../icons/light.svg";
import {ReactComponent as GroupIcon} from "../../icons/group-icon.svg";
import {ReactComponent as EditIcon} from "../../icons/edit.svg";
import {ReactComponent as DocumentIcon} from "../../icons/document.svg";
import useClasses from "../../utils/themeUtils";
import theme from "../../theme";
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import {upgradeAction} from "../../utils/commonEvents";
import './menu.scss';


const drawerWidth = 260;
export const useMenuStyles = theme => ({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    menuUtilsLarge: {
        direction: 'ltr',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '30px',
        marginTop: '15px',
        padding: theme.spacing(0, 1),
    },
    menuUtilsSmall: {
        direction: 'ltr',
        display: 'flex',
        padding: theme.spacing(0, 1),
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    content: {
        flexGrow: 1,
        maxWidth: '100%',
        width: `calc(100% - ${drawerWidth}px)`,
        minWidth: '320px' //this is the mobile lowest values
    },
    menuFooter: {
        direction: 'ltr',
        marginTop: 'auto',
        [theme.breakpoints.down('md')]: {
            marginTop: '0',
            marginBottom: "auto"
        },
        '& div p': {
            color: 'white',
            fontFamily: variables.Avenir,
            fontSize: '11px',
            fontWeight: 900,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            textAlign: 'center',
            whiteSpace: 'break-spaces',
            margin: '5px'
        },
        '& .footer-menu': {
            color: 'white',
            fontFamily: variables.AvenirBook,
            fontSize: '12px',
            '& a': {
                margin: '0 5px'
            }
        }
    },
    logoHolder: {
        direction: 'ltr',
        // padding: '0 20px',
        paddingLeft: '19px',
        width: '100%',
        marginBottom: '17px',
        marginTop: '17px',

    },
    navStyle: {
        color: 'white',
        textDecoration: 'none',
    },
    userNavStyle: {
        color: 'blue',
        textDecoration: 'none',
    },
    submenuStyle: {
        // backgroundColor: 'rgba(255,255,255,0.35)'
        backgroundColor: variables.secondaryDarkBlue,
        // marginLeft:  '50px' ,
        // marginRight: '20px',
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
    },
    submenuDrawerStyle: {
        // backgroundColor: 'rgba(255,255,255,0.35)'
        backgroundColor: variables.secondaryDarkBlue,
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
    },
    page_title: {
        margin: 0,
        fontFamily: 'Avenir',
        fontSize: '48px',
        fontWeight: '900',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '0.8',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: variables.primary,
    },
    pageHeader: {
        backgroundColor: 'white',
        boxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)'
    }
});

const CreatorTitleText = styled(Typography)({
    marginLeft: "10px",
    fontFamily: "AvenirBook",
    color: variables.grey,
    alignSelf: "center"
});

const TitleElement = styled(Typography)({
    fontFamily: variables.Gotham,
    fontSize: '38px',
    lineHeight: '48px',
    fontWeight: '500',
    letterSpacing: '-1.5px',
    textAlign: 'center',
    textShadow: 'none',
    color: variables.primary,
    margin: '0 16px 0 0'
});

const ListItemTextSBB = styled(ListItemText)({
    color: variables.textLightest,
    '& .MuiListItemText-primary': {
        fontFamily: variables.AvenirMedium,
        fontSize: '16px',
    },
    '&.orange':{
        color: '#FFC700'
    }
});

const UserListItem = styled(ListItem)({
        color: 'blue',
        fontFamily: variables.AvenirMedium,
        fontSize: '16px',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: variables.primary,
            color: 'white',
            '& .MuiListItemIcon-root': {
                color: 'white',
            }
        },
        '& .MuiListItemIcon-root': {
            color: variables.primary,
        }
    });

const SBBMenuList = styled(MenuList)((props, windowheight)=>({
    direction: "ltr",
    padding: "0px",
        [props.theme.breakpoints.down("376")]: {
            marginTop: "20px",
        },
        [props.theme.breakpoints.down("md")]: {
            marginTop: (window.innerHeight < 746) ? "30px" : "35px",
        },
        [props.theme.breakpoints.up("md")]: {
            overflowX: (window.innerHeight < 756 && windowheight === null) ? "auto" : "clip",
        }
    }
))

const scrollingPrimaryDesign = {
    '&::-webkit-scrollbar': {
        width: '12px'
    },
    '&::-webkit-scrollbar-track': {
        boxShadow: `inset 5px 0 0 ${variables.darkBackground}, inset -5px 0 0 ${variables.darkBackground}`,
        backgroundColor: 'white',
        marginTop: '0',
        marginBottom: '0'
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '0',
        backgroundColor: variables.textLight,
    }
};

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    ...scrollingPrimaryDesign
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
});

const closedMobileMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    marginLeft: '-1px',
    [theme.breakpoints.up('sm')]: {
        width: 0,
    },
});

const AppBar = styled(MuiAppBar)(({theme, open, ismobile}) => ({
    // zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: variables.darkBackground,
    ...(ismobile && {}),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(ismobile && open && {
        width: "100%",
    }),
    ...(!ismobile && {
        background: 'transparent',
        backgroundColor: 'transparent',
        boxShadow: 'unset',
        color: variables.primary,
    }),
    ...(!ismobile && !open && {
        marginLeft: theme.spacing(7),
        width: `calc(100% - ${theme.spacing(7)} - 1px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),

}));

const SBBTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: variables.secondaryLightBackground,
        color: "white",
        boxShadow: "none",
        fontSize: 16,
        fontFamily: variables.AvenirLight,
        margin: 0,
        paddingBottom: "12px",
        paddingTop: "12px",
        maxHeight: "90px",
        display: "flex",
    },
});

const Drawer = styled(MuiDrawer)(
    ({theme, open, ismobile}) => ({
        zIndex: 1099,
        paddingTop: '40px',
        width: drawerWidth,
        backgroundColor: variables.darkBackground,
        flexShrink: 0,
        direction: 'rtl',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            maxWidth: "58px",
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
        ...(ismobile && !open && {
            ...closedMobileMixin(theme),
            '& .MuiDrawer-paper': closedMobileMixin(theme),
        }),

    }),
);

const SBBExternalIcon = styled(Icon) ({
        "& svg":{
            "&:hover":{
                "& path": {
                    stroke: "white",
                }
        }
    }
});

const SBBTooltipText = (item) =>{
    return <>
        {item.title}{item.isExternal && <span id="sbb-external-icon" style={{marginLeft: "10px", display: "inline-flex"}}><SBBExternalIcon component={ExternalIcon}></SBBExternalIcon></span>}
    </>
}

const RightMenu = (props) => {
    const {classes, location} = props;
    const [hideUserMenu, setHideUserMenu] = useState(false);
    const [serverLink, setServerLink] = useState('');
    const [fullName, setFullName] = useState(getFullName());

    const refreshMenuInfo = () => {
        setFullName(getFullName());
    }

    useEffect(() => {
        document.addEventListener('user_refreshed', refreshMenuInfo);
        return () => {
            document.removeEventListener('user_refreshed', refreshMenuInfo)
        };
    },[]);

    useEffect(() => {
        setHideUserMenu(getUserMenuException(props.location.pathname));
    }, [props.location]);

    const titleFinder = (pathname) =>{
        let pageTitle = null;

        Object.keys(pages).map((v)=>{
            if(pathname.indexOf(v) > 0){
                pageTitle = pages[v];
            }
        });
        return pageTitle;
    }

    return hideUserMenu ? null : <Toolbar className={classes.pageHeader}>
        <div className={clsx(classes.title, classes.page_title)}>
            {/*<PageTitle location={location} />*/}
            <TitleElement variant="h1" style={{display: "flex", flexDirection: "row"}}>
                {titleFinder(location.pathname)}
                <CreatorTitleText sx={{display: {xs:"none", sm: "none", md: "none", lg: "none", xl: "block"}}}>
                    Master Brand Sponsorships
                </CreatorTitleText>
            </TitleElement>
        </div>
        <div className={classes.grow} />
        <div>
            <Grid
                className="headerUserMenu"
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                columnSpacing={2}
            >
                <Grid item></Grid>
                <Divider orientation="vertical" variant="middle" flexItem/>
                <Grid item container xs="auto"
                      justifyContent="flex-end"
                      flexWrap="nowrap"
                      alignItems="center">
                    <Grid item xs sx={{display: {xs:"none", sm: "none", md: "none", lg: "block", xl: "block"}}}>
                        <h6>{fullName}</h6>
                    </Grid>
                    <Grid container item xs alignItems="center" wrap="nowrap">
                        {props.desktopToolbarUser}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </Toolbar>
};

const UserAvatar = () => {
    const [userAvatar, setUserAvatar] = useState(getProfilePicture())
    const refreshMenuInfo = () => {
        setUserAvatar(getProfilePicture());
    }
    useEffect(() => {
        document.addEventListener('user_refreshed', refreshMenuInfo);
        return () => {
            document.removeEventListener('user_refreshed', refreshMenuInfo)
        };
    },[]);

    return <Avatar sx={{height: "32px", width: "32px"}} src={userAvatar} />
}

const getIcon = (subMenu) => {
    return subMenu.svgSrc &&
            <ListItemIcon sx={{color: 'inherit'}}>
                {subMenu.svgSrc && subMenu.svgSrc === 'EventIcon' && <EventIcon/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'BookIcon' && <BookIcon/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'LocalOfferIcon' && <LocalOfferIcon/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'CalculateIcon' && <CalculateIcon/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'CollectionsBookmarkOutlinedIcon' && <CollectionsBookmarkOutlinedIcon/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'PersonIcon' && <PersonIcon/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'ReceiptIcon' && <ReceiptIcon/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'ExitToAppIcon' && <ExitToAppIcon/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'GroupIcon' && <GroupIcon viewBox="0 0 19 11"/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'PartnerIcon' && <PartnerIcon viewBox="0 0 20 14" />}
                {subMenu.svgSrc && subMenu.svgSrc === 'AccountIcon' && <AccountIcon viewBox="0 0 12 13"/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'CoursesIcon' && <CoursesIcon viewBox="0 0 16 13"/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'BrandOfferIcon' && <BrandOfferIcon viewBox="0 0 24 24"/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'NewIcon' && <NewIcon viewBox="0 0 46 25"/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'MediaKitIcon' && <MediaKitIcon viewBox="-1 -1 18 20"/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'DashboardIcon' && <DashboardIcon viewBox="0 0 24 24"/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'ValuationsIcon' && <ValuationsIcon viewBox="0 0 24 24"/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'LightIcon' && <LightIcon viewBox="0 0 24 24"/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'EditIcon' && <EditIcon viewBox="0 0 24 24"/>}
                {subMenu.svgSrc && subMenu.svgSrc === 'DocumentIcon' && <DocumentIcon viewBox="0 0 24 24"/>}
            </ListItemIcon>
}

class sbbMenu extends Component {
    state = {
        sideMenu: false,
        anchorEl: null,
        subMenu: [],
        subSubMenu: null,
        isMobile: false,
        userAnchor: null,
        ssmExtended: false,
        animating: false,
    };
    constructor() {
        super();
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        if (window.innerWidth < 901) {
            this.setState({...this.state, isMobile: true, sideMenu: false});
        } else {
            let sideMenu = true;
            // let menuVersion = localStorage.getItem('sideMenu');
            // if (menuVersion) {
            //     sideMenu = menuVersion === 'true';
            // }
            this.setState({...this.state, isMobile: false, sideMenu: sideMenu});
        }
    }

    toggleAnchor = (menuItem, isSubSubMenu) => {
        if(!isSubSubMenu) {
            if(this.state.subMenu.includes(menuItem.id)){
                let menus = this.state.subMenu;
                let remove_index = menus.indexOf(menuItem.id);
                menus.splice(remove_index, 1);
                this.setState({...this.state, anchorEl: null, subMenu: menus});
            }else{
                let menus = this.state.subMenu;
                menus.push(menuItem.id);
                this.setState({...this.state, anchorEl: null, subMenu: menus});
            }

        } else{
            if (this.state.subSubMenu === menuItem.id) {
                this.setState({...this.state, anchorEl: null, subSubMenu: null});
            } else {
                this.setState({...this.state, anchorEl: null, subSubMenu: menuItem.id});
            }
        }
    };

    toggleDrawer = () => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        let new_open = !this.state.sideMenu;
        // localStorage.setItem('sideMenu', String(new_open));
        this.setState({...this.state, sideMenu: new_open, anchorEl: null, subMenu: [], animating: true});
        setTimeout(() => {
            this.setState({...this.state, animating: false})
        }, theme.transitions.duration.enteringScreen);
    };


    handleDrawerClose = () => {
        if (window.innerWidth < 901) {
            this.setState({...this.state, sideMenu: false, anchorEl: null, subMenu: []});
        }
    };

    getMobileMenuUsingMap = (withPoper) => {
        const drawerList = {
            marginRight: this.state.sideMenu ? '20px' : '0',
            color: variables.textLightest,
            paddingLeft: this.state.sideMenu ? '15px' : (withPoper ?'27px' : '15px'), // previously ": 28px"
            paddingRight: '10px',
            textDecoration: 'none',
            height: "40px",
        };
        const location = this.props.location;
        const hasSubscription = hasActiveSubscription();
        const user = getUser();

        function listMultiple(withPoper, subMenu, classes, sideMenu) {
            if(!withPoper){
            return <ListItem key={subMenu.key + "list_items"} button
                             color="inherit" style={drawerList}
                             className={subMenu['classes']}>
                {getIcon(subMenu)}
                {!subMenu.svgSrc ?
                      <ListItemTextSBB primary={subMenu.title}  sx={{ marginLeft: "40px"}} classes={{paper: classes.menuText}}/>
                    : <ListItemTextSBB primary={subMenu.title} classes={{paper: classes.menuText}}/>}
                {subMenu.isExternal && <ListItemIcon sx={{paddingLeft: "15px"}}><ExternalIcon viewBox="0 0 20 17"/></ListItemIcon>}
            </ListItem>
            } else if(withPoper && subMenu.svgSrc){
                return <ListItem key={subMenu.key + "list_items"} button color="inherit" style={drawerList} className={subMenu['classes']}>
                    {getIcon(subMenu)}
                </ListItem>
            }
            else return <></>;
        }

        function listMultipleTriger(isMobile, menuItem, classes, isActive, that, withPoper) {
            if(!withPoper) {
                    return <ListItem onClick={() => that.toggleAnchor(menuItem, false)} button
                                     className={isActive ? 'active' : ''}
                                     key={menuItem.key + "list_item_triggers"}
                                     color="inherit"
                                     style={drawerList}>
                        {getIcon(menuItem)}
                        <span style={{fontFamily: variables.AvenirHeavy, color: "white", marginRight: "5px"}}>{menuItem.boldItem}</span>
                        <ListItemTextSBB primary={menuItem.title}
                                          classes={{paper: classes.menuText}} />
                        {menuItem.upgradeAddon && !hasSubscription ?
                            <ListItemSecondaryAction>
                                <Button color="warning"
                                        data-title={'Menu Upgrade Button'}
                                        style={{background: 'unset', color: '#EFBB00', borderColor:'#EFBB00'}}
                                        edge="end"
                                        aria-label="ugrade"
                                        size={"small"}
                                        title={"unlock now"}
                                        onClick={(e)=>{
                                            upgradeAction(e)
                                        }}>

                                    <LockIcon fontSize={'small'} />
                                </Button>
                            </ListItemSecondaryAction>
                        :
                            <ListItemIcon>
                                {Boolean(that.state.subMenu?.includes(menuItem.id)) ? <ExpandMore/> : <></>}
                            </ListItemIcon>
                        }

                    </ListItem>
            }
            else return <></>
        }

        function listMultipleSubTrigger(menuItem, classes, isActive, that) {
            return <ListItem button
                             onClick={() => that.toggleAnchor(menuItem,true)}
                             className={isActive ? 'active' : ''}
                             key={menuItem.key + "list_item_triggers"}
                             color="inherit"
                             style={drawerList}>
                {menuItem.svgSrc &&
                    <ListItemIcon className="colorable-sidebar-ssm-icon">
                        {menuItem.svgSrc && menuItem.svgSrc === 'AccountIcon' && <AccountIcon viewBox="0 0 12 13"/>}
                        {menuItem.svgSrc && menuItem.svgSrc === 'CoursesIcon' && <CoursesIcon viewBox="0 0 16 13"/>}
                        {menuItem.svgSrc && menuItem.svgSrc === 'BrandOfferIcon' && <BrandOfferIcon viewBox="0 0 24 24"/>}
                        {menuItem.svgSrc && menuItem.svgSrc === 'NewIcon' && <NewIcon viewBox="0 0 46 25"/>}
                        {menuItem.svgSrc && menuItem.svgSrc === 'LocalOfferOutlinedIcon' && <LocalOfferOutlinedIcon/>}
                        {menuItem.svgSrc && menuItem.svgSrc === 'MediaKitIcon' && <MediaKitIcon viewBox="-1 -1 18 20"/>}
                        {menuItem.svgSrc && menuItem.svgSrc === 'DashboardIcon' && <DashboardIcon viewBox="0 0 24 24"/>}
                        {menuItem.svgSrc && menuItem.svgSrc === 'ValuationsIcon' && <ValuationsIcon viewBox="0 0 24 24"/>}
                        {menuItem.svgSrc && menuItem.svgSrc === 'GroupIcon' && <GroupIcon viewBox="0 0 19 11"/>}
                        {menuItem.svgSrc && menuItem.svgSrc === 'PartnerIcon' && <PartnerIcon viewBox="0 0 20 14" />}
                        {menuItem.svgSrc && menuItem.svgSrc === 'LightIcon' && <LightIcon viewBox="0 0 24 24"/>}
                    </ListItemIcon>
                }
                <ListItemTextSBB primary={menuItem.title} classes={{paper: classes.menuText}}/>
                {Boolean(menuItem.id === that.state.subSubMenu) ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
        }

        function listTooltipMenuItems(ssmExt, menuItem, classes, isActive, that) {
            if(!withPoper) {
                return <SBBTooltip title={SBBTooltipText(menuItem)}
                                   interactive={1}
                                   aria-label={menuItem.title}
                                   placement="right-start"
                                   className="sbb-sidebar-tooltip"
                                   sx={{
                                       display: "flex",
                                       justifyContent: "center",
                                       height: "48px",
                                       pointerEvents: "auto"
                                   }}
                                   TransitionProps={{timeout: 0}}
                                   enterDelay={0} leaveDelay={0}
                >
                    <ListItem
                        className={isActive ? 'active' : ''}
                        key={menuItem.key + "list_item_triggers"}
                        color="inherit"
                        style={drawerList}>
                        {menuItem.svgSrc &&
                            <ListItemIcon className="colorable-sidebar-ssm-icon">
                                {menuItem.svgSrc && menuItem.svgSrc === 'AccountIcon' && <AccountIcon viewBox="0 0 12 13"/>}
                                {menuItem.svgSrc && menuItem.svgSrc === 'CoursesIcon' && <CoursesIcon viewBox="0 0 16 13"/>}
                                {menuItem.svgSrc && menuItem.svgSrc === 'BrandOfferIcon' && <BrandOfferIcon viewBox="0 0 24 24"/>}
                                {menuItem.svgSrc && menuItem.svgSrc === 'NewIcon' && <NewIcon viewBox="0 0 46 25"/>}
                                {menuItem.svgSrc && menuItem.svgSrc === 'LocalOfferOutlinedIcon' && <LocalOfferOutlinedIcon/>}
                                {menuItem.svgSrc && menuItem.svgSrc === 'MediaKitIcon' && <MediaKitIcon viewBox="-1 -1 18 20"/>}
                                {menuItem.svgSrc && menuItem.svgSrc === 'DashboardIcon' && <DashboardIcon viewBox="0 0 24 24"/>}
                                {menuItem.svgSrc && menuItem.svgSrc === 'ValuationsIcon' && <ValuationsIcon viewBox="0 0 24 24"/>}
                                {menuItem.svgSrc && menuItem.svgSrc === 'GroupIcon' && <GroupIcon viewBox="0 0 19 11"/>}
                                {menuItem.svgSrc && menuItem.svgSrc === 'PartnerIcon' && <PartnerIcon viewBox="0 0 20 14" />}
                                {menuItem.svgSrc && menuItem.svgSrc === 'LightIcon' && <LightIcon viewBox="0 0 24 24"/>}

                            </ListItemIcon>
                        }
                    </ListItem>
                </SBBTooltip>
            }
            else return <></>
        }

        function listSingle(menuItem, classes) {
            return <ListItem button color="inherit" style={drawerList} key={menuItem.key + "item_cs"} className={menuItem['classes']}>
                <ListItemIcon>
                    {menuItem.svgSrc && menuItem.svgSrc === 'AccountIcon' && <AccountIcon viewBox="0 0 12 13"/>}
                    {menuItem.svgSrc && menuItem.svgSrc === 'CoursesIcon' && <CoursesIcon viewBox="0 0 16 13"/>}
                    {menuItem.svgSrc && menuItem.svgSrc === 'BrandOfferIcon' && <BrandOfferIcon viewBox="0 0 24 24"/>}
                    {menuItem.svgSrc && menuItem.svgSrc === 'NewIcon' && <NewIcon viewBox="0 0 46 25"/>}
                    {menuItem.svgSrc && menuItem.svgSrc === 'LocalOfferOutlinedIcon' && <LocalOfferOutlinedIcon/>}
                    {menuItem.svgSrc && menuItem.svgSrc === 'MediaKitIcon' && <MediaKitIcon viewBox="-1 -1 18 20"/>}
                    {menuItem.svgSrc && menuItem.svgSrc === 'DashboardIcon' && <DashboardIcon viewBox="0 0 24 24"/>}
                    {menuItem.svgSrc && menuItem.svgSrc === 'ValuationsIcon' && <ValuationsIcon viewBox="0 0 24 24"/>}
                    {menuItem.svgSrc && menuItem.svgSrc === 'GroupIcon' && <GroupIcon viewBox="0 0 19 11"/>}
                    {menuItem.svgSrc && menuItem.svgSrc === 'PartnerIcon' && <PartnerIcon viewBox="0 0 20 14" />}
                    {menuItem.svgSrc && menuItem.svgSrc === 'LightIcon' && <LightIcon viewBox="0 0 24 24"/>}

                </ListItemIcon>
                <ListItemTextSBB primary={menuItem.title} classes={{paper: classes.menuText}}/>
            </ListItem>
        }

        return <SBBMenuList windowheight={this.state.anchorEl}>
            {!hasSubscription && (roles.CREATOR.indexOf(user?.role) >= 0) &&
                <ListItem button color="#FFC700" style={drawerList}>
                    <ListItemIcon className={"orange"}>
                        <LockOpenIcon />
                    </ListItemIcon>
                    <ListItemTextSBB primary={user?.canceled_subscription ? "Upgrade" : "Start Trial"}  className={"orange"} sx={{pl: "2px"}} onClick={(e)=>{
                        upgradeAction(e)
                    }}/>
                </ListItem>
            }
            {this.props.structure.map((menuItem) => {
            let submenu = null;
            let subsubmenu = null;
            let child_list_to = [];
            let ssm_child_list_to = [];
            let ssmtriggerDom = null;
            const {classes} = this.props;
            const sideMenu = this.state.sideMenu;
            const ssmExt = this.state.ssmExtended;
            if (menuItem['multiple']) {

                submenu = menuItem.menu.map((subMenu) => {
                    if(subMenu['multiple']) {
                        subsubmenu = subMenu.menu.map((subSubMenu)=> {
                            return (subSubMenu.premium && !hasSubscription) ? <span
                                    onClick={(e)=>{
                                            upgradeAction(e, 'Menu ' + subSubMenu.title)
                                            }}>
                                {withPoper ? <SBBTooltip title={SBBTooltipText(subSubMenu)}
                                                         interactive={1}
                                                         key={subSubMenu.id}
                                                         aria-label={subSubMenu.title}
                                                         placement="right-start"
                                                         className="sbb-sidebar-tooltip"
                                                         disableHoverListener={(location.pathname === subSubMenu.state)}
                                                         sx={{display: "flex", justifyContent: "center", height: "48px",borderRadius: withPoper ? "0" : "0px 10px 10px 0px"}}
                                                         TransitionProps={{ timeout: 0 }}
                                                         enterDelay={0} leaveDelay={0}>
                                    {listMultiple(withPoper, subSubMenu, classes)}

                                </SBBTooltip> : listMultiple(withPoper, subSubMenu, classes, sideMenu)}
                                </span> :
                                <NavLink
                                    to={subSubMenu.state}
                                    onClick={this.handleDrawerClose}
                                    className={classes.navStyle + (withPoper ? ' poper' : '')}
                                    key={subSubMenu.id}
                                    label={subSubMenu.title}>
                                    {withPoper ? <SBBTooltip title={SBBTooltipText(subSubMenu)}
                                                         interactive={1}
                                                         key={subSubMenu.id}
                                                         aria-label={subSubMenu.title}
                                                         placement="right-start"
                                                         className="sbb-sidebar-tooltip"
                                                         disableHoverListener={(location.pathname === subSubMenu.state)}
                                                         sx={{display: "flex", justifyContent: "center", height: "48px",borderRadius: withPoper ? "0" : "0px 10px 10px 0px"}}
                                                         TransitionProps={{ timeout: 0 }}
                                                         enterDelay={0} leaveDelay={0}>
                                    {listMultiple(withPoper, subSubMenu, classes)}
                                </SBBTooltip> : listMultiple(withPoper, subSubMenu, classes, sideMenu)}
                            </NavLink>;
                        });
                        ssm_child_list_to = subMenu.menu.map((subSubMenu) => {
                            return subSubMenu.state
                        });
                        let isActive = ssm_child_list_to.indexOf(location.pathname) > -1;
                        if (withPoper) {
                            ssmtriggerDom = <>
                                {listTooltipMenuItems(ssmExt ,subMenu, classes, isActive, this)}
                            </>;
                        } else {
                            ssmtriggerDom = listMultipleSubTrigger(subMenu, classes, isActive, this)
                        }
                        this.state.ssmExtended = Boolean(subMenu.id === this.state.subSubMenu);

                        if(withPoper && !ssmExt) {
                            return [
                                ssmtriggerDom,
                                <Collapse in={Boolean(subMenu.id !== this.state.subSubMenu)} timeout="auto"
                                          unmountOnExit
                                          className={this.state.sideMenu ? classes.submenuDrawerStyle : classes.submenuStyle}>
                                    {subsubmenu}
                                </Collapse>]
                        }
                        else{
                            return [
                                ssmtriggerDom,
                                <Collapse in={Boolean(subMenu.id === this.state.subSubMenu)} timeout="auto"
                                          unmountOnExit
                                          className={this.state.sideMenu ? classes.submenuDrawerStyle : classes.submenuStyle}>
                                    {subsubmenu}
                                </Collapse>]
                        }
                    }
                    if(subMenu.state.pathname){
                        return (subMenu.premium && !hasSubscription) ? <span
                            onClick={e=>{
                            upgradeAction(e, 'Menu ' + subMenu.title)
                        }}>
                            {withPoper ? <SBBTooltip title={SBBTooltipText(subMenu)}
                                                     interactive={1}
                                                     aria-label={subMenu.title}
                                                     placement="right-start"
                                                     className="sbb-sidebar-tooltip"
                                                     disableHoverListener={(location.pathname === subMenu.state)}
                                                     sx={{display: "flex", justifyContent: "center", height: "48px"}}
                                                     TransitionProps={{ timeout: 0 }}
                                                     enterDelay={0} leaveDelay={0}>
                                {listMultiple(withPoper, subMenu, classes)}
                            </SBBTooltip> : listMultiple(withPoper, subMenu, classes, sideMenu)}
                        </span> : <a
                                href={subMenu.state.pathname}
                                target="_blank"
                                onClick={this.handleDrawerClose}
                                className={classes.navStyle +  (withPoper ? ' poper' : '')}
                                key={subMenu.id}>
                                {withPoper ? <SBBTooltip title={SBBTooltipText(subMenu)}
                                                         interactive={1}
                                                         aria-label={subMenu.title}
                                                         placement="right-start"
                                                         className="sbb-sidebar-tooltip"
                                                         disableHoverListener={(location.pathname === subMenu.state)}
                                                         sx={{display: "flex", justifyContent: "center", height: "48px"}}
                                                         TransitionProps={{ timeout: 0 }}
                                                         enterDelay={0} leaveDelay={0}>
                                {listMultiple(withPoper, subMenu, classes)}
                            </SBBTooltip> : listMultiple(withPoper, subMenu, classes, sideMenu)}
                        </a>;
                    } else {
                        return (subMenu.premium && !hasSubscription) ? <span onClick={(e)=>{
                                upgradeAction(e, 'Menu ' + subMenu.title)
                            }}>
                                {withPoper ? <SBBTooltip title={SBBTooltipText(subMenu)}
                                                         interactive={1}
                                                         aria-label={subMenu.title}
                                                         placement="right-start"
                                                         className="sbb-sidebar-tooltip"
                                                         disableHoverListener={(location.pathname === subMenu.state)}
                                                         sx={{display: "flex", justifyContent: "center", height: "48px"}}
                                                         TransitionProps={{ timeout: 0 }}
                                                         enterDelay={0} leaveDelay={0}
                                >
                                    {listMultiple(withPoper, subMenu, classes)}
                                </SBBTooltip> : listMultiple(withPoper, subMenu, classes, sideMenu)}
                            </span> :
                            <NavLink
                                to={subMenu.state}
                                onClick={this.handleDrawerClose}
                                className={classes.navStyle +  (withPoper ? ' poper' : '')}
                                key={subMenu.id}
                                label={subMenu.title}>
                                {withPoper ? <SBBTooltip title={SBBTooltipText(subMenu)}
                                                     interactive={1}
                                                     aria-label={subMenu.title}
                                                     placement="right-start"
                                                     className="sbb-sidebar-tooltip"
                                                     disableHoverListener={(location.pathname === subMenu.state)}
                                                     sx={{display: "flex", justifyContent: "center", height: "48px"}}
                                                     TransitionProps={{ timeout: 0 }}
                                                     enterDelay={0} leaveDelay={0}
                            >
                                {listMultiple(withPoper, subMenu, classes)}
                            </SBBTooltip> : listMultiple(withPoper, subMenu, classes, sideMenu)}
                        </NavLink>;
                    }
                });
                child_list_to = menuItem.menu.map((subMenu) => {
                    return subMenu.state
                });
                let isActive = child_list_to.indexOf(location.pathname) > -1;
                let triggerDom = <></>;
                if (withPoper) {
                    triggerDom = <SBBTooltip interactive={1} title={SBBTooltipText(menuItem)} aria-label={menuItem.title}
                                                 placement="right-start">
                        {listMultipleTriger(this.state.isMobile, menuItem, classes, isActive, this, withPoper)}
                    </SBBTooltip>;
                } else {
                    triggerDom = listMultipleTriger(this.state.isMobile, menuItem, classes, isActive, this, withPoper)
                }
                return [
                    triggerDom,
                    <Collapse in={!Boolean(this.state.subMenu?.includes(menuItem.id))} timeout="auto" unmountOnExit
                              className={!Boolean(this.state.subMenu?.includes(menuItem.id)) ? classes.submenuDrawerStyle : classes.submenuStyle}>
                        {submenu}
                    </Collapse>]
            } else {
                return <NavLink
                    to={menuItem.state}
                    onClick={this.handleDrawerClose}
                    className={classes.navStyle + (withPoper ? ' poper' : '')}
                    target={menuItem['target']}
                    key={menuItem.id+'item'}
                    // isActive={(match, location) => {
                    //         if (match) {
                    //             return true;
                    //         }
                    //         return isActiveLink(menuItem);
                    //     }
                    // }
                    label={menuItem.title}>
                    {withPoper ?
                        <SBBTooltip interactive={1} title={SBBTooltipText(menuItem)} aria-label={menuItem.title}
                                    placement="right-start"
                                    className="sbb-sidebar-tooltip"
                                    disableHoverListener={(location.pathname === menuItem.state)}
                                    sx={{display: "flex", justifyContent: "center", height: "48px",}}
                                    TransitionProps={{ timeout: 0 }}
                                    enterDelay={0} leaveDelay={0}
                        >
                            {listSingle(menuItem, classes)}
                        </SBBTooltip> : listSingle(menuItem, classes)}
                </NavLink>
            }
        })}</SBBMenuList>;
    };

    addPlatform = () => {
        const addPlatform = new Event('addPlatform');
        this.handleDrawerClose();
        document.dispatchEvent(addPlatform);
    };

    handleProfileMenuClose = () => {
        this.setState({...this.state, userAnchor: null});
    };

    handleProfileMenuOpen = (event) => {
        if (this.state.userAnchor) {
            this.setState({...this.state, userAnchor: null});
        } else {
            this.setState({...this.state, userAnchor: event.currentTarget});
        }
    };

    getDesktopUserMenu = () => {
    const {classes} = this.props;
    let submenu = this.props.user_structure.menu.map((subMenu, index) => {
        if (subMenu.title === 'divider') {
            return <Divider key={'divider_menu' + index}/>;
        }
        return <NavLink
                className={classes.userNavStyle}
                to={subMenu.state}
                key={subMenu.id + 'menu-list-systems'}
                onClick={this.handleProfileMenuClose}
                label={subMenu.title}>
                <UserListItem button color="inherit" key={subMenu.id + 'list-systems'}>
                    <ListItemIcon>
                        {subMenu.svgSrc && subMenu.svgSrc === 'PersonIcon' && <PersonIcon/>}
                        {subMenu.svgSrc && subMenu.svgSrc === 'ReceiptIcon' && <ReceiptIcon/>}
                        {subMenu.svgSrc && subMenu.svgSrc === 'ExitToAppIcon' && <ExitToAppIcon/>}
                    </ListItemIcon>
                    <ListItemText primary={subMenu.title}/>
                </UserListItem>
            </NavLink>
    });
    return submenu;
};

    desktopToolbarUser = () => {
        const isProfileMenuOpen = Boolean(this.state.userAnchor);

        let arrow = <ExpandMore sx={{color: variables.darkBackground}} className="hover" key="openUserMenu" onClick={() => {
            this.refs.UserAvatar.click();
        }}/>;
        if (isProfileMenuOpen) {
            arrow = <ExpandLess sx={{color: variables.darkBackground}}  className="hover" key="closeUserMenu" onClick={() => {
                this.refs.UserAvatar.click();
            }}/>;
        }

        return [<IconButton
            ref="UserAvatar"
            key="desktopUserMenu"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            className="userAvatar"
            onClick={this.handleProfileMenuOpen}
            color="inherit">
            <UserAvatar />
        </IconButton>,
            arrow,
            <Popper id="menu-appbar"
                    style={{zIndex: 5}}
                    disablePortal={true}
                    open={isProfileMenuOpen}
                    anchorEl={this.state.userAnchor}
                    key={'usermenuhandledtopsystemchek'}
                    placement={'bottom'}>
                <ClickAwayListener onClickAway={this.handleProfileMenuOpen}>
                    <Paper sx={{boxShadow: "0px 8px 23px rgba(0, 0, 0, 0.11)", borderRadius: "10px"}}>
                        {this.getDesktopUserMenu()}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        ]
    };

    render() {
        const {isThisMobile, classes, location} = this.props;
        const isProfileMenuOpen = Boolean(this.state.userAnchor);
        const hasSubscription = hasActiveSubscription();
        return (
            <>
                <CssBaseline/>
                <AppBar position="absolute" open={this.state.sideMenu} ismobile={!!isThisMobile ? 1 : 0}
                        className={'main-menu-top-system'}>
                    {isThisMobile ?
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.toggleDrawer()}
                                edge="start"
                                className={clsx(classes.menuButton)}>
                                {isThisMobile ?
                                    (this.state.sideMenu ? <CloseIcon sx={{color: variables.textLightest}}/> : <MenuIconMobile viewBox="0 0 24 24"/>)
                                    :
                                    (this.state.sideMenu ? <MenuIconMobile viewBox="0 0 24 24"/> : <MenuIconMobile  viewBox="0 0 24 24" style={{transform: "rotate(180deg)"}}/>)
                                }

                            </IconButton>
                            <Link to={'/'}>
                                <LogoImage viewBox="0 0 152 30"/>
                            </Link>
                            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            </Typography>
                            <IconButton
                                ref="UserAvatar"
                                key="desktopUserMenu"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                sx={{ width: 24, height: 24}}
                                className="sbb-toolbar-avatar-button"
                                onClick={this.handleProfileMenuOpen}
                                color="inherit">
                                <UserAvatar />
                            </IconButton>
                            <Popper id="menu-appbar"
                                    style={{zIndex: 5}}
                                    disablePortal={true}
                                    open={isProfileMenuOpen}
                                    anchorEl={this.state.userAnchor}
                                    key={'usermenuhandledtopsystemchek'}
                                    placement={'bottom-end'}>
                                <ClickAwayListener onClickAway={this.handleProfileMenuOpen}>
                                    <Paper sx={{boxShadow: "0px 8px 23px rgba(0, 0, 0, 0.11)", borderRadius: "10px", marginTop: {xs: "16px", sm: "22px"}}}>
                                        {this.getDesktopUserMenu()}
                                    </Paper>
                                </ClickAwayListener>
                            </Popper>
                        </Toolbar>

                        : <RightMenu location={location} classes={classes}
                                     desktopToolbarUser={this.desktopToolbarUser()}/>}
                </AppBar>

                <Drawer
                    variant={isThisMobile ? 'temporary' : 'permanent'}
                    open={this.state.sideMenu}
                    ismobile={isThisMobile ? 1 : 0}
                    anchor='left'
                    onClose={this.handleDrawerClose}
                    className={this.state.sideMenu ? 'side-menu' : 'side-menu small-menu'}>
                    {!isThisMobile || this.state.sideMenu ?
                        <div className={classes.logoHolder}>
                            <Grid container
                                  justifyContent="space-between"
                                  alignItems="center"
                            >

                                { !isThisMobile &&
                                    <>
                                        { this.state.sideMenu &&
                                            <Grid item>
                                                <Link to={'/'}>
                                                    <LogoImage viewBox="0 0 152 30" style={{width: '100%', height: 'auto'}}/>
                                                </Link>
                                            </Grid>
                                        }
                                    {/*<Grid item>*/}
                                    {/*    <IconButton*/}
                                    {/*        color="inherit"*/}
                                    {/*        aria-label="open drawer"*/}
                                    {/*        onClick={this.toggleDrawer()}*/}
                                    {/*        edge="start"*/}
                                    {/*        className={clsx(classes.menuButton)}>*/}
                                    {/*        {isThisMobile ?*/}
                                    {/*            (this.state.sideMenu ? <CloseIcon sx={{color: variables.textLightest}}/> : <MenuIconMobile viewBox="0 0 24 24"/>)*/}
                                    {/*            :*/}
                                    {/*            (this.state.sideMenu ? <MenuIconClose viewBox="0 0 24 24"/> : <MenuIconClose viewBox="0 0 24 24" style={{transform: "rotate(180deg)"}}/>)*/}
                                    {/*        }*/}
                                    {/*    </IconButton>*/}
                                    {/*</Grid>*/}
                                    </>
                                }
                            </Grid>
                        </div>
                        :
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.toggleDrawer()}
                            edge="start"
                            className={clsx(classes.menuButton)}>
                            <MenuIconOpen viewBox="0 0 24 24" />
                        </IconButton>
                    }

                    {isThisMobile || this.state.sideMenu ? this.getMobileMenuUsingMap() : this.getMobileMenuUsingMap(true)}
                    {/*<Button color="white" onClick={this.addPlatform}>Add</Button>*/}

                        <div className={clsx('side-menu', classes.menuFooter, {
                            [classes.drawerOpen]: this.state.sideMenu,
                            [classes.drawerClose]: !this.state.sideMenu,
                        })}>
                            {/*<Collapse in={!this.state.ssmExtended && !this.state.animating} timeout="auto"*/}
                            {/*          unmountOnExit>*/}
                            {/*    <Grid container*/}
                            {/*          justifyContent="center"*/}
                            {/*          alignItems="center"*/}
                            {/*          spacing={0}>*/}
                            {/*        {!hasSubscription ?*/}
                            {/*            <Grid container*/}
                            {/*                  className="footer-menu-banner"*/}
                            {/*                  direction="column"*/}
                            {/*                  alignItems="center"*/}
                            {/*                  p={2}*/}
                            {/*                  m={2}>*/}
                            {/*                <Grid item container justifyContent="center">*/}
                            {/*                    <AffiliateLogo viewBox="0 0 126 26"/>*/}
                            {/*                </Grid>*/}
                            {/*                <Grid item container justifyContent="center">*/}
                            {/*                    <h4 className="color-blue">Upgrade to Professional</h4>*/}
                            {/*                </Grid>*/}
                            {/*                <Grid item container justifyContent="center">*/}
                            {/*                    <h5>Ready to grow your audience & make $$ with Brands?</h5>*/}
                            {/*                </Grid>*/}
                            {/*                <Grid item container justifyContent="center">*/}
                            {/*                    <Button color="warning"*/}
                            {/*                            style={{background: variables.accentDark}}*/}
                            {/*                            size="medium"*/}
                            {/*                            onClick={(e)=>{*/}
                            {/*                                upgradeAction(e, 'Menu Upgrade Now')*/}
                            {/*                            }}*/}
                            {/*                            variant="contained"*/}
                            {/*                            title="Upgrade">Learn More</Button>*/}
                            {/*                </Grid>*/}
                            {/*            </Grid>*/}
                            {/*            :*/}
                            {/*            <TiffanyBanner />*/}
                            {/*        }*/}
                            {/*    </Grid>*/}
                            {/*</Collapse>*/}

                            <div>
                                <p className="footer-copy-right">
                                    Copyright {(new Date().getFullYear())} © New Media Trader Inc.
                                </p>
                                <div className="text-center footer-menu">
                                    <Link to={'/terms-of-use'} style={{color: 'white'}}>
                                        {this.state.sideMenu ? 'Terms Of Service' : 'TOS'}
                                    </Link>
                                    {this.state.sideMenu ? '|' : < br/>}
                                    <Link to={'/privacy-policy'} style={{color: 'white'}}>
                                        {this.state.sideMenu ? 'Privacy Policy' : 'Privacy'}
                                    </Link>
                                    {this.state.sideMenu ? '|' : < br/>}
                                    <a style={{color: 'white'}} href="mailto:support@socialbluebook.com"
                                       title="Mail to Support">Help</a>
                                </div>
                            </div>
                        </div>
                </Drawer>
            </>
        )
    }
}

function withIsMobileHook(Component) {
    return function WrappedComponent(props) {
        const theme = useTheme();
        const myHookValue = useMediaQuery('(max-width:900px)');
        const myMenuClasses = useClasses(useMenuStyles);
        const navigate = useNavigate();
        let location = useLocation();
        return <Component {...props} isThisMobile={myHookValue} classes={myMenuClasses} theme={theme} location={location}
                          navigate={navigate}/>;
    }
}

export default withIsMobileHook(sbbMenu);

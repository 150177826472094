/***
    Using the following integrations:

    GTM has been declared on app load as: window.gtm_push
    Google Analytics is: window.ga
    Active Campaign manager is: window.acTrackVisit(userEmail)
    TikTOk Pixel Lib: ttq
    FacebookPixel: fbq
    Mixpanel: window.mixpanel
***/
import {getUser} from "./user";

export const pageTrack = (page) => {
    let user = getUser();

    //active campaign manager page tracker:
    if('acTrackVisit' in window && user){
        // Active campaign tracker per user email:
        window.acTrackVisit(user['email']);
    }

    //Google Analytics:
    if('ga' in window){
        window.ga('send', 'pageview', page);
    }

    //facebook view content:
    if(fbq){
        fbq('track', 'ViewContent');
        fbq('track', 'PageView');
    }

    //facebook view content:
    if(ttq){
        ttq.track('ViewContent');
    }
};

export const loginTrack = (event, details) => {
    if(window.mixpanel){
        try {
            window.mixpanel.track(event, details);
        }
        catch(err) {
            console.error('Mixpanel error: ' + err.message);
        }
    }

    //Google Analytics:
    if('ga' in window){
        window.ga('send', event, details);
    }
};

export const tracker = (event, conf) => {
    // tracker for custom events:
    if(window.mixpanel){
        try {
            window.mixpanel.track(event, conf);
        }
        catch(err) {
            console.error('Mixpanel error: ' + err.message);
        }
    }

    //on GA:
    if('ga' in window){
        try {
            window.ga('send', 'event', conf);
        }
        catch(err) {
            console.error('Google error: ' + err.message);
        }
    }

    if(conf['eventCategory'] === 'PaymentRequestSent'){
        if(fbq){
            fbq('track', 'InitiateCheckout');
        }
        if(ttq){
            ttq.track('InitiateCheckout');
        }
    }
    if(conf['eventCategory'] === 'PaymentRequestSaved'){
        if(fbq){
            fbq('track', 'Subscribe');
        }
        if(ttq){
            ttq.track('CompletePayment');
        }
    }
};

export const GET_PRO_TIPS = 'GET_PRO_TIPS';
export const GET_PRO_TIPS_SUCCESS = 'GET_PRO_TIPS_SUCCESS';
export const GET_PRO_TIPS_ERROR = 'GET_PRO_TIPS_ERROR';

export const getProTips = () => {
    return {
        type: GET_PRO_TIPS,
    }
};

export default function(state = [], action) {
    const response = action.response;
    switch(action.type) {
        case GET_PRO_TIPS_SUCCESS:
            return { ...state, response };
        case GET_PRO_TIPS_ERROR:
            return { ...state, response };
        default:
            return state;
    }
}


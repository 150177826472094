import "core-js/stable";
import "regenerator-runtime/runtime";
import {call, put} from "redux-saga/effects";
import * as api_types from "./reducer";
import {getDeals, getSchedule} from "./requests";

export function* getDealsSaga() {
    try {
        const response = yield call(getDeals);
        if(response.status === 200){
            yield put({type: api_types.GET_DEALS_SUCCESS, response});
        }else{
            yield put({type: api_types.GET_DEALS_ERROR, response});
        }
    } catch (error) {
        yield put({type: api_types.GET_DEALS_ERROR, error})
    }
}

export function* getScheduleSaga() {
    try {
        const response = yield call(getSchedule);
        if(response.status === 200){
            yield put({type: api_types.GET_DEALS_SUCCESS, response});
        }else{
            yield put({type: api_types.GET_DEALS_ERROR, response});
        }
    } catch (error) {
        yield put({type: api_types.GET_DEALS_ERROR, error})
    }
}

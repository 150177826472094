import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Box, Button, styled } from "@mui/material";
import tabithathewholistic from "../../images/home/tabithathewholistic.png";
import sixmonthslater from "../../images/home/Six Months Later.jpg";
import bethandcourt from "../../images/home/Life with Beth and Court.jpg";
import aaron from "../../images/home/Unnecessary Bluntness Sports Talk.jpg";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import Grid from "@mui/material/Grid";

const images = [
    {
        quote: "I've closed at least 4 paid brand deals so far this year, and one was my first 4-figure deal!",
        author: 'Tabitha Howard',
        social: 'tabithathewholistic',
        image: tabithathewholistic,
    },
    {
        quote: "We took what we learned, applied it the next day and landed an $1,800 brand deal.",
        author: 'Beth & Court',
        social: 'Life with Beth and Court',
        image: bethandcourt,
    },
    {
        quote: "I've successfully landed a brand deal from a cold email I sent based on the strategies you went over. This week I successfully upsold a brand, doubling the money I would have gotten.",
        author: 'Josh Teder',
        social: 'Six Months Later',
        image: sixmonthslater,
    },
    {
        quote: "Two brand agencies reached out for small deals. Even had the courage to counter one offer with a 25% higher figure. Was shocked that the Company came back with even higher than that. Hoping to keep the deals rolling in!",
        author: 'Aaron',
        social: 'Unnecessary Bluntness Sports Talk',
        image: aaron,
    },
];

// Styled Container for the Carousel
const CarouselContainer = styled(Box)({
    maxWidth: 860,
    flexGrow: 1,
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

// Styled Navigation Buttons
const NavigationButton = styled(Button)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    color: "black",
    zIndex: 10,
    minWidth: "40px",
    height: "55px",
    borderRadius: "50%",
    opacity: 0.8,
    "&:hover": {
        opacity: 1
    },
    [theme.breakpoints.down("lg")]: {
        display: "none", // Hides buttons on screens smaller than 600px
    },
}));

const LeftButton = styled(NavigationButton)({
    left: "-60px", // Moves button outside the left side
});

const RightButton = styled(NavigationButton)({
    right: "-60px", // Moves button outside the right side
});

// Styled container for pagination dots (outside the swiper)
const PaginationContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    marginTop: "10px", // Adjust distance below carousel
    ".swiper-pagination-bullet": {
        backgroundColor: "#222", // Bullet color
        opacity: "0.6",
    },
    ".swiper-pagination-bullet-active": {
        backgroundColor: "#000", // Active bullet color
        opacity: "1",
    },
});

function CarouselSwipableSection() {
    const swiperRef = useRef(null);


    return (<div className="xs-12">
                <CarouselContainer>
                    {/* Left Navigation Button */}
                    <LeftButton onClick={() => swiperRef.current?.slidePrev()}>
                        <ChevronLeft />
                    </LeftButton>

                    {/* Swiper Component */}
                    <Swiper
                        ref={swiperRef}
                        modules={[Navigation, Pagination]}
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{
                            el: ".custom-pagination",
                            clickable: true, // Allow users to click pagination dots
                        }}
                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                    >
                        {images.map((step, index) => (
                            <SwiperSlide key={index}>
                                <div className="testimonial-slide">
                                    <div className="testimonial-content">
                                        <div className="testimonial-quote">
                                            <em>"{step.quote}"</em>
                                        </div>
                                        <div className="testimonial-info">
                                            <div>
                                                <div className="testimonial-author-name">{step.author}</div>
                                                <div>{step.social}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <img
                                        src={step.image}
                                        loading="lazy"
                                        alt={step.author}
                                        className="testimonial-image"
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    {/* Right Navigation Button */}
                    <RightButton onClick={() => swiperRef.current?.slideNext()}>
                        <ChevronRight />
                    </RightButton>
                </CarouselContainer>
                <Grid container flex={"row"} xs={12} alignItems="center" justifyContent="center">
                    <PaginationContainer>
                        <div className="custom-pagination"></div>
                    </PaginationContainer>
                </Grid>
            </div>
)}

export default CarouselSwipableSection;
import React from 'react';
import ReactDOM from "react-dom/client";
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import theme from './theme';
import './css/basics.scss';
import { Provider } from 'react-redux';
import configureStore from './redux/configureStore';
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from 'notistack';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={configureStore()}>
            <SnackbarProvider maxSnack={4}>
                <Router>
                    <App />
                </Router>
            </SnackbarProvider>
        </Provider>
    </ThemeProvider>);

import axios from "axios";
import {baseUrl, httpParamSerializer, transformRequest} from "../../utils/baseurl";

export const getUserService = () => {
    const GET_USER_ENDPOINT = baseUrl('session-info/');
    return apiCall(GET_USER_ENDPOINT, 'GET');
};

export const getUserProfileService = (mode='owner', custom_url=null) => {
    let GET_USER_ENDPOINT = baseUrl('api/public_profile/');
    let parameters = {};
    if(mode){
       parameters['mode'] = mode;
    }
    if(custom_url){
        parameters['custom_url'] = custom_url;
    }
    return apiCall(GET_USER_ENDPOINT, 'GET',null, parameters);
};

export const getUserProfileInfoService = (mode='owner', custom_url=null) => {
    let GET_USER_ENDPOINT = baseUrl('api/public_profile_info/');
    let parameters = {};
    if(mode){
       parameters['mode'] = mode;
    }
    if(custom_url){
        parameters['custom_url'] = custom_url;
    }
    return apiCall(GET_USER_ENDPOINT, 'GET',null, parameters);
};

export const getUserProfileContentService = (mode='owner', custom_url=null) => {
    let GET_USER_ENDPOINT = baseUrl('api/public_profile_content/');
    let parameters = {};
    if(mode){
       parameters['mode'] = mode;
    }
    if(custom_url){
        parameters['custom_url'] = custom_url;
    }
    return apiCall(GET_USER_ENDPOINT, 'GET',null, parameters);
};

export const sendUserProfileInfo = (formData) => {
    let SET_USER_ENDPOINT = baseUrl('api/about_you/');
    return userApiFilesCall(SET_USER_ENDPOINT, 'POST', formData);
};

export const removeUserProfilePhoto = (formData) => {
    let REMOVE_USER_PHOTO_ENDPOINT = baseUrl('api/about_creator_delete/');
    return userApiFilesCall(REMOVE_USER_PHOTO_ENDPOINT, 'POST', formData);
};

export const requestDeletion = () => {
    const DELETE_USER_ENDPOINT = baseUrl('delete-user/ekdam/');
    return apiCall(DELETE_USER_ENDPOINT, 'POST');
};

export const cancelDeletionRequest = () => {
    const CANCEL_DELETE_USER_ENDPOINT = baseUrl('delete-user/cancel/');
    return apiCall(CANCEL_DELETE_USER_ENDPOINT, 'POST');
};

export const changeUserEmail = (data) => {
    const CHANGE_USER_EMAIL_ENDPOINT = baseUrl('change-email/');
    return apiCall(CHANGE_USER_EMAIL_ENDPOINT, 'POST', data);
};

export const changePassword = (data) => {
    const CHANGE_USER_PASSWORD_ENDPOINT = baseUrl('change-password/');
    return apiCall(CHANGE_USER_PASSWORD_ENDPOINT, 'POST', data);
};

export const changePhoneSettings = (data) => {
    const CHANGE_USER_PASSWORD_ENDPOINT = baseUrl('api/notifications/');
    return userApiFilesCall(CHANGE_USER_PASSWORD_ENDPOINT, 'POST', data);
};

export const changeCurrency = (data) => {
    const CURRENCY_ENDPOINT = baseUrl('api/change-currency/');
    return apiCall(CURRENCY_ENDPOINT, 'POST', data);
};

export const getCurrencies = () => {
    const CURRENCY_ENDPOINT = baseUrl('api/change-currency/');
    return apiCall(CURRENCY_ENDPOINT, 'GET');
};

export const confirmIdentity = (data) => {
    const CONFIRM_IDENTITY_ENDPOINT = baseUrl('confirm-identity/');
    return apiCall(CONFIRM_IDENTITY_ENDPOINT, 'POST', data);
};

export const confirmPrivacyRules = () => {
    const CONFIRM_PRIVACY_ENDPOINT = baseUrl('api/accept_privacy_terms/');
    return apiCall(CONFIRM_PRIVACY_ENDPOINT, 'POST', { 'accept' : 'yes' });
};

export const confirmTutorial = (name) => {
    const CONFIRM_TUTORIAL = baseUrl('user-tutorials/');
    return apiCall(CONFIRM_TUTORIAL, 'POST', { 'name' : name });
};

export const getBrandInterest = () => {
    const URL = baseUrl('brand-interest/');
    return userApiCall(URL, 'GET');
};

export const getContentInterest = () => {
    const URL = baseUrl('api/content/');
    return userApiCall(URL, 'GET');
};

export const sendContentInterest = (data) => {
    const URL = baseUrl('api/content/');
    return userApiFilesCall(URL, 'POST', data);
};

export const getPrimaryPlatform = () => {
    const URL = baseUrl('api/primary-platform/');
    return userApiCall(URL, 'GET');
};

export const setPrimaryPlatform = (data) => {
    const URL = baseUrl('api/primary-platform/');
    return userApiFilesCall(URL, 'POST', data);
};

export const getInfluencerSize = () => {
    const URL = baseUrl('api/influencer-size/');
    return userApiCall(URL, 'GET');
};

export const setInfluencerSize = (data) => {
    const URL = baseUrl('api/influencer-size/');
    return userApiFilesCall(URL, 'POST', data);
};

export const getPrimaryGoals = () => {
    const URL = baseUrl('api/primary-goals/');
    return userApiCall(URL, 'GET');
};

export const setPrimaryGoals = (data) => {
    const URL = baseUrl('api/primary-goals/');
    return userApiFilesCall(URL, 'POST', data);
};

export const setBrandInterest = (data) => {
    const URL = baseUrl('brand-interest/');
    return userApiFilesCall(URL, 'POST', data);
};

export const deleteUserAccount = () => {
    const REMOVE_USER = baseUrl('delete-user/');
    return apiCall(REMOVE_USER, 'POST');
};

export const setUserProfilePlatformsDisplaySettings = (data) => {
    const SAVE_PLATFORM_MEDIA = baseUrl('api/platforms/');
    return userApiCall(SAVE_PLATFORM_MEDIA, 'POST', data);
};

export const removeShowcase = (showcaseId) => {
    const REMOVE_SHOWCASE_MEDIA = baseUrl('api/branded_examples_edit/?DELETE=""');
    let data = httpParamSerializer({branded_id: showcaseId});
    return userApiCall(REMOVE_SHOWCASE_MEDIA, 'POST', data);
};

export const pushShowcase = (formData) => {
    const REMOVE_SHOWCASE_MEDIA = baseUrl('api/branded_examples_edit/?PUT=""');
    return userApiFilesCall(REMOVE_SHOWCASE_MEDIA, 'POST', formData);
};

export const getToken = () => {
    const MESSAGE = baseUrl('api/send-message/');
    return userApiCall(MESSAGE, 'GET');
};

export const sendUserMessage = (formData) => {
    const MESSAGE = baseUrl('api/send-message/');
    let data = httpParamSerializer(formData);
    return userApiCall(MESSAGE, 'POST', data);
};

export const confirmEmail = (formData) => {
    const CONFIRM = baseUrl('generate-email-confirmation/');
    let data = httpParamSerializer(formData);
    return apiCall(CONFIRM, 'POST', data);
};

export const setUserStep = (data) => {
    const CONFIRM = baseUrl('user_steps/');
    return apiCall(CONFIRM, 'POST', data);
};

export const userFunctionUsage = (tag) => {
    const CONFIRM = baseUrl('function-view/');
    let data = {'type': tag}
    return apiCall(CONFIRM, 'POST', data);
};

export const resendConfirmEmail = () => {
    const CONFIRM = baseUrl('generate-email-confirmation/');
    return apiCall(CONFIRM, 'GET');
};

export const getAiEmail = (data) => {
    const AI_EMAIL = baseUrl('bot-email-generator/');
    return apiCall(AI_EMAIL, 'POST', data);
};

export const sendFilesToAI = (data) => {
    const AI_FILES = baseUrl('analyse-contract/');
    return userApiFilesCall(AI_FILES, 'POST', data);
};

const apiCall = (url, method, data, params=null) => {
    const config = {
        method: method,
        url: url,
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        withXSRFToken: true,
    };
    if(data){
        config['data'] = data;
    }
    if(params){
        config['params'] = params;
    }
    return axios.request(config);
};

const userApiCall = (url, method, data, params=null) => {
    const config = {
        method: method,
        url: url,
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        withXSRFToken: true,
        headers: {
            'Authorization': 'Basic dXNlcjpjaDhkczht',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }
    };
    if(data){
        config['data'] = data;
    }
    if(params){
        config['params'] = params;
    }
    return axios.request(config);
};

const userApiFilesCall = (url, method, data) => {
    const config = {
        method: method,
        url: url,
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        withXSRFToken: true,
        transformRequest: transformRequest,
        headers: {
            'Authorization': 'Basic dXNlcjpjaDhkczht',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }
    };
    if(data){
        config['data'] = data;
    }
    return axios.request(config);
};

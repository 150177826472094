import "core-js/stable";
import "regenerator-runtime/runtime";
import {call, put} from "redux-saga/effects";
import * as api_types from "./reducer";
import {getUserProfileService, getUserService} from "./requests";

export function* getUserSaga() {
    try {
        const response = yield call(getUserService);
        if(response.status === 200){
            yield put({type: api_types.GET_USER_SUCCESS, response});
        }else{
            yield put({type: api_types.GET_USER_ERROR, response});
        }

    } catch (error) {
        yield put({type: api_types.GET_USER_ERROR, error})
    }
}

export function* getUserProfileSaga() {
    try {
        const response = yield call(getUserProfileService);
        if(response.status === 200){
            yield put({type: api_types.GET_USER_PROFILE_SUCCESS, response});
        }else{
            yield put({type: api_types.GET_USER_PROFILE_ERROR, response});
        }

    } catch (error) {
        yield put({type: api_types.GET_USER_PROFILE_ERROR, error})
    }
}


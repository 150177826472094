export const GET_TALENTS = 'GET_TALENTS';
export const GET_TALENTS_SUCCESS = 'GET_TALENTS_SUCCESS';
export const GET_TALENTS_ERROR = 'GET_TALENTS_ERROR';

export const getTalents = () => {
    return {
        type: GET_TALENTS,
    }
};

export default function(state = [], action) {
    const response = action.response;
    switch(action.type) {
        case GET_TALENTS_SUCCESS:
            return { ...state, response };
        case GET_TALENTS_ERROR:
            return { ...state, response };
        default:
            return state;
    }
}


import React from 'react';
import MuiCircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import variables from './../../../css/theme.module.scss';
import styled from '@emotion/styled';
import Grid from "@mui/material/Grid";
import useClasses from "../../../utils/themeUtils";

const CircularProgress = styled(MuiCircularProgress)(
    ({ blue }) => ({
        position: 'absolute',
        left: 'calc( 50% - 20px)',
        top: 'calc( 50% - 20px)',
        color: variables.secondary,
        ...(blue && {
            color: variables.primary,
        }),
    }),
);
export function CustomCircularProgress(props) {
    return (
            <CircularProgress
                variant="indeterminate"
                size={40}
                thickness={4}
                {...props}
            />
    );
}

const newStyle = {
    root: {
        flexGrow: 1,
        position: 'relative'
    }
}

export function SBBBackDropBase(props){
    return (<Backdrop
        style={{ color: props.color, zIndex: props.zIndex ? props.zIndex : 1110 }}
        open={props.open}>
        <CircularProgress blue={props.blue}
                          size={40}
                          thickness={4}/>
    </Backdrop>);
}

export function SBBBackDropLoader(props){
    return <SBBBackDropBase {...props} />
}

export function SBBBackDropBlueLoader(props){
    return <SBBBackDropBase blue={1} {...props} />
}

export default function SBBCircularLoader() {
    const classes = useClasses(newStyle);
    return (
        <div className={classes.root}>
            <CustomCircularProgress />
        </div>
    );
}

export function FreeSBBCircularBlueLoader() {
    return (
        <CustomCircularProgress blue={1}/>
    );
}

export function FreeSBBCircularYellowLoader() {
    return (
        <CustomCircularProgress />
    );
}

export function SBBCircularBlueLoader() {
    const classes = useClasses(newStyle);
    return (
        <div className={classes.root}>
            <FreeSBBCircularBlueLoader />
        </div>
    );
}

export const FullPageLoading = () => {
    return <Grid sx={{width: '100%', height: '100vh'}} justifyContent="center" alignItems="center">
        <FreeSBBCircularBlueLoader />
    </Grid>
};

export const renderLoaderBlue = () => <Grid container item alignItems="center" justifyContent="center" className="margin-top-50 padding-top-30"><SBBCircularBlueLoader /></Grid>;

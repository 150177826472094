import React, { useMemo } from 'react';
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import Slide from "@mui/material/Slide/Slide";

const useClasses = stylesElement => {
    const theme = useTheme();
    return useMemo(() => {
        const rawClasses = typeof stylesElement === 'function'
            ? stylesElement(theme)
            : stylesElement;
        const prepared = {};

        Object.entries(rawClasses).forEach(([key, value = {}]) => {
            prepared[key] = css(value);
        });

        return prepared;
    }, [stylesElement, theme]);
};

export default useClasses;

export const UpTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
import "core-js/stable";
import "regenerator-runtime/runtime";
import {call, put} from "redux-saga/effects";
import * as api_types from "./reducer";
import {getCourses} from "./requests";

export function* getCoursesSaga() {
    try {
        const response = yield call(getCourses);
        if(response.status === 200){
            yield put({type: api_types.GET_COURSES_SUCCESS, response});
        }else{
            yield put({type: api_types.GET_COURSES_ERROR, response});
        }
    } catch (error) {
        yield put({type: api_types.GET_COURSES_ERROR, error})
    }
}


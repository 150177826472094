import React, {lazy, Suspense, useState} from 'react';
import {renderLoaderBlue} from "../../components/common/loader/circularLoader";
import Grid from '@mui/material/Grid';
import './aboutUs.scss';
import {v4 as uuidv4} from "uuid";
import AddIcon from '@mui/icons-material/Add';
import DefaultMenu from "../../components/menu/DefaultMenu";
import {ListItem, ListItemButton} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {hasUser} from "../../utils/cookies";
import FooterComponent from "../../components/footer/FooterComponent";
import {Link, useLocation} from "react-router-dom";
import Resources_img from "../../images/about/Resources.png";
import Group_img from "../../images/about/Group.png";
import MediaKit_img from "../../images/about/MediaKit.png";
import Valuations_img from "../../images/about/Valuations.png";

const AboutUs = () => {
    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const goToSection = (chapter) => {
        let element = document.getElementById(chapter.id + "-header");
        if (element) {
            let headerOffset = 20;
            let elementPosition = element.getBoundingClientRect().top;
            let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    return <Grid className='terms-of-use'>
        <DefaultMenu />
        <Grid className='top-section' px={4}>
            <Grid className="section-text-holder">
                <h2>
                    About Social Bluebook
                </h2>
                <p>
                    Being a content creator <strong>IS</strong> a business and requires a professional mindset and strategy. We can help with this!
                </p>
            </Grid>
        </Grid>
        <Grid container xs className='body-section' justifyContent='center'>
            <h1>
                Social Bluebook empowers creators with:
            </h1>
            <Grid container className="container-box" direction="row">
                <Grid item xs={12} sm={6} md={3} px={4} py={6}>
                    <img src={Resources_img} alt="" className="image-6" />
                    <h2>Step-by-Step<br />Courses</h2>
                    <p>Courses and content that will help you work smarter and make more money in your
                    creator business.</p>
                </Grid>
                <Grid item xs={12} sm={6} md={3} px={4} py={6}>
                    <img src={Group_img} alt="" className="image-6" />
                    <h2>Instant Access to Coaching</h2>
                    <p>Learn from experts who specialize in topics such as brand partnerships, YouTube, Instagram, and TikTok.</p>
                </Grid>
                <Grid item xs={12} sm={6} md={3} px={4} py={6}>
                    <img src={Valuations_img}  alt="" className="image-6" />
                    <h2>Know Your Worth</h2>
                    <p>A game-changing tool that provides you a monetary value that you can use in brand deal negotiations.</p>
                </Grid>
                <Grid item xs={12} sm={6} md={3} px={4} py={6}>
                    <img src={MediaKit_img} alt="" className="image-6" />
                    <h2>An automated <br />
                        Media Kit</h2>
                    <p>A ready-to-send resume that you can send brands so it's clear who you are and what audience you reach.</p>
                </Grid>
            </Grid>
            <h3>
                And much more...
            </h3>
        </Grid>
        <Grid container className='body-section-2' justifyContent="center">
            <Grid item xs={11} sm={11} md={9} lg={9} xl={8}>
                <h2>
                    A message to any aspiring creators out there...
                </h2>
                <p>Dear Creator,</p>
                <p>I'm so excited about the steps you're
                    taking to become a professional. You ARE a legitimate business owner, entrepreneur, and CEO of your
                    content creation business.</p>
                <p>You have a voice and a story to tell that has the ability to shape
                    today's world, and the future. You wield greater power than you know and one of the greatest keys to
                    your success will be your ability to persevere through the ups and downs of your creator journey.</p>
                <p>As a fellow business owner and entrepreneur, I know from personal experience how challenging and
                    lonely running a small business can feel. Often, I have felt like I just wanted someone to talk to
                    that was in a similar position to me.</p>
                <p>Well, I want to issue you a promise that, here at Social
                    Bluebook, we are building just that: a community and learning center where creators, aspiring for
                    professionalism, can find the mentorship, education, and and business-building tools needed to reach
                    your goals.</p>
                <p>We want to get to know you, understand your challenges, and hopefully share some
                    of our wisdom that can boost the positive trajectory of your efforts.</p>
                <p>I'm confident that as a
                    company &amp; service we can help elevate your professionalism so that your creator business can
                    soar to new heights. </p>
                <p>We can't wait to meet you!</p>
            </Grid>

        </Grid>
        <FooterComponent />
    </Grid>
}

export default AboutUs;

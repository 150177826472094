import "core-js/stable";
import "regenerator-runtime/runtime";
import { all } from 'redux-saga/effects';
import watchUserAuthentication from './auth';
import watchUserApi, {watchUserProfileApi} from './user';
import watchDashComponentsApi from './dashboardCards';
import watchProTips from './proTips';
import watchCourses from './academy';
import watchBlogArticles from './blog';
import watchDeals from './brandDeals';
import watchTalents from './talent';

export default function* startForman() {
    yield all([
        watchUserAuthentication(),
        watchUserApi(),
        watchDashComponentsApi(),
        watchProTips(),
        watchCourses(),
        watchBlogArticles(),
        watchDeals(),
        watchTalents(),
        watchUserProfileApi(),
    ])
}


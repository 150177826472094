import axios from "axios";
import {baseUrl, transformRequest} from "../../utils/baseurl";
import {getCookie} from "../../utils/cookies";

export const getQuoteApi = () => {
    return baseUrl('download-quotation/')
}

export const getContractAction = () => {
    return baseUrl('generate-contract/')
}

export const getTalentLinkers = () => {
    const GET_TALENTS_ENDPOINT = baseUrl('links/');
    const config = getConfig('GET', GET_TALENTS_ENDPOINT);

    return axios.request(config);
};

export const getTalentsSoft = () => {
    const GET_TALENTS_ENDPOINT = baseUrl('talent_details/');
    const config = getConfig('GET', GET_TALENTS_ENDPOINT);
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
};

export const getTalents = () => {
    const GET_TALENTS_ENDPOINT = baseUrl('talent_details/');
    const config = getConfig('POST', GET_TALENTS_ENDPOINT);
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
};

export const getPartialTalents = () => {
    const GET_TALENTS_ENDPOINT = baseUrl('talent_list/');
    const config = getConfig('GET', GET_TALENTS_ENDPOINT);
    return axios.request(config)
};

export const getTalentDetails = (talentId) => {
    const GET_TALENTS_ENDPOINT = baseUrl(`blade/talent_details/?id=${talentId}&type=basic`);
    const config = getConfig('GET', GET_TALENTS_ENDPOINT);
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
};

export const getLatestMonth = (talentId) => {
    const GET_TALENTS_ENDPOINT = baseUrl(`blade/talent_details/?id=${talentId}&type=30_days`);
    const config = getConfig('GET', GET_TALENTS_ENDPOINT);
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
};

export const getLatest3Months = (talentId) => {
    const GET_TALENTS_ENDPOINT = baseUrl(`blade/talent_details/?id=${talentId}&type=3_months_weekly`);
    const config = getConfig('GET', GET_TALENTS_ENDPOINT);
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
};

export const getTalentInstagramLastSixMonths = (talentId) => {
    const GET_TALENTS_ENDPOINT = baseUrl(`blade/talent_details/?id=${talentId}&type=instagram_last_6_months`);
    const config = getConfig('GET', GET_TALENTS_ENDPOINT);
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
};

export const getWeeklyTimeAudience = (talentId) => {
    const GET_TALENTS_ENDPOINT = baseUrl(`blade/talent_details/?id=${talentId}&type=audience_times`);
    const config = getConfig('GET', GET_TALENTS_ENDPOINT);
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
};


export const getLatestVideos = (talentId) => {
    const GET_TALENTS_ENDPOINT = baseUrl(`blade/talent_details/?id=${talentId}&type=latest_videos_stats`);
    const config = getConfig('GET', GET_TALENTS_ENDPOINT);
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
};

export const getAgeGender = (talentId) => {
    const GET_TALENTS_ENDPOINT = baseUrl(`blade/talent_details/?id=${talentId}&variable_response=true&type=age_gender_statistics`);
    const config = getConfig('GET', GET_TALENTS_ENDPOINT);
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
};

export const getDevices = (talentId) => {
    const GET_TALENTS_ENDPOINT = baseUrl(`blade/talent_details/?id=${talentId}&variable_response=true&type=device_statistics`);
    const config = getConfig('GET', GET_TALENTS_ENDPOINT);
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
};

export const getGeographicData = (talentId) => {
    const GET_TALENTS_ENDPOINT = baseUrl(`blade/talent_details/?id=${talentId}&variable_response=true&type=geographic_statistics`);
    const config = getConfig('GET', GET_TALENTS_ENDPOINT);
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
};

export const addTikTok = (tiktok_username) => {
    const ADD_TIKTOK_ENDPOINT = baseUrl('tiktok-receive-data/');
    const config = getConfig('GET', ADD_TIKTOK_ENDPOINT+'?username='+tiktok_username.trim().replace('@', ''));
    return axios.request(config);
};

export const removePlatform = (talentId) => {
    const DELETE_TALENTS_ENDPOINT = baseUrl(`talent_delete/?id=${talentId}`);
    const config = getConfig('DELETE', DELETE_TALENTS_ENDPOINT);
    return axios.request(config);
};

export const refreshTalentValues = (platform_id) => {
    const REFRESH_TALENTS_ENDPOINT = baseUrl('refresh_values/');

    const config = getConfig('POST', REFRESH_TALENTS_ENDPOINT);
    if(platform_id){
        config['data'] = {
            'platform_id' : platform_id,
        }
    }
    return axios.request(config);
};

export const saveTwitchManualData = (data) => {
    const SAVE_TWITCH_ENDPOINT = baseUrl('twitch_stats/');
    const config = getConfig('POST', SAVE_TWITCH_ENDPOINT, data);

    return axios.request(config);
};

export const savePlatformSettings = (data) => {
    const SAVE_PLATFORMS_ENDPOINT = baseUrl('set-talent-profile/');
    const config = getConfig('POST', SAVE_PLATFORMS_ENDPOINT, data);
    return axios.request(config);
}

export const addManualPlatform = (data) => {
    const SAVE_PLATFORMS_ENDPOINT = baseUrl('add-manual-talent/');
    const config = getConfig('POST', SAVE_PLATFORMS_ENDPOINT, data);
    config['transformRequest'] = transformRequest;
    config['headers'] = {
        'Authorization': 'Basic dXNlcjpjaDhkczht',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };
    return axios.request(config);
}


export const addShortsData = (data) => {
    const SAVE_PLATFORMS_ENDPOINT = baseUrl('add-shorts-data/');
    const config = getConfig('POST', SAVE_PLATFORMS_ENDPOINT, data);
    config['transformRequest'] = transformRequest;
    config['headers'] = {
        'Authorization': 'Basic dXNlcjpjaDhkczht',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };
    return axios.request(config);
}


const getConfig = (method, url, data) => {
    return {method, url,
        withXSRFToken: true,
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        data
    };
}

import axios from "axios";
import {baseUrl} from "../../utils/baseurl";

export const getDashboardCards = () => {
    const GET_DASH_CARDS_ENDPOINT = baseUrl('dashboard-cards/');
    const config = {
        method: 'GET',
        url: GET_DASH_CARDS_ENDPOINT,
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        withXSRFToken: true,
    };
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
};


export const getPowerUserStatus = () => {
    const GET_POWER_USER_STATUS = baseUrl('power-user-status/');
    const config = {
        method: 'GET',
        url: GET_POWER_USER_STATUS,
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        withXSRFToken: true,
    };
    return axios.request(config)
        .then(response => {
            return response;
        }).catch(error => {
            return error;
        });
};

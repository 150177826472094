export const GET_BLOG_ARTICLES = 'GET_BLOG_ARTICLES';
export const GET_BLOG_ARTICLES_SUCCESS = 'GET_BLOG_ARTICLES_SUCCESS';
export const GET_BLOG_ARTICLES_ERROR = 'GET_BLOG_ARTICLES_ERROR';

export const getBlogArticles = () => {
    return {
        type: GET_BLOG_ARTICLES,
    }
};

export default function(state = [], action) {
    const response = action.response;
    switch(action.type) {
        case GET_BLOG_ARTICLES_SUCCESS:
            return { ...state, response };
        case GET_BLOG_ARTICLES_ERROR:
            return { ...state, response };
        default:
            return state;
    }
}


export const GET_DEALS = 'GET_DEALS';
export const GET_DEALS_SUCCESS = 'GET_DEALS_SUCCESS';
export const GET_DEALS_ERROR = 'GET_DEALS_ERROR';

export const GET_SCHEDULE = 'GET_SCHEDULE';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_ERROR = 'GET_SCHEDULE_ERROR';

export const getDeals = () => {
    return {type: GET_DEALS}
};

export const getSchedule = () => {
    return {type: GET_SCHEDULE}
};

export default function(state = [], action) {
    const response = action.response;
    switch(action.type) {
        case GET_DEALS_SUCCESS:
            return { ...state, response };
        case GET_DEALS_ERROR:
            return { ...state, response };
        case GET_SCHEDULE_SUCCESS:
            return { ...state, response };
        case GET_SCHEDULE_ERROR:
            return { ...state, response };
        default:
            return state;
    }
}


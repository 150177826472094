export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR';

export const loginUserAction = (user) => {
    return {
        type: LOGIN_USER,
        user
    }
};

export const logoutUserAction = (user) => {
    return {
        type: LOGOUT_USER,
        user
    }
};

export default function(state = [], action) {
    const response = action.response;
    switch(action.type) {
        case LOGIN_USER_SUCCESS:
            return { ...state, response };
        case LOGIN_USER_ERROR:
            return { ...state, response };
        case LOGOUT_USER_SUCCESS:
            return { ...state, response };
        case LOGOUT_USER_ERROR:
            return { ...state, response };
        default:
            return state;
    }
}


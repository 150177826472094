import Joyride from 'react-joyride';
import React, {useEffect, useState} from "react";
import {getHideTutorialKey, tours_locations} from "./tours";
import './tours.scss';
import styled from "@emotion/styled";
import variables from './../../css/theme.module.scss';
import Button from "@mui/material/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {confirmTutorial} from "../../redux/user/requests";

const TooltipBody = styled.div`
  background: #353D50;
  border-radius: 10px;
  min-width: 290px;
  max-width: 300px;
`;

const TooltipContent = styled.div`
  color: #fff;
  font-family: ${variables.AvenirLight};
  padding: 20px;
`;

const TooltipTitle = styled.h3`
  text-align: left;
  color: #fff;
  font-family: ${variables.AvenirHeavy};
  margin: 0;
  padding: 16px 0 16px 16px
`;

const TooltipFooter = styled.div`
  background: #353D50;
  display: flex;
  justify-content: space-between;
  padding: 0 16px 16px 16px;
`;

const ACTIONS = {
    INIT: 'init',
    START: 'start',
    STOP: 'stop',
    RESET: 'reset',
    RESTART: 'restart',
    PREV: 'prev',
    NEXT: 'next',
    GO: 'go',
    INDEX: 'index',
    CLOSE: 'close',
    SKIP: 'skip',
    UPDATE: 'update',
}
const EVENTS = {
    TOUR_START: 'tour:start',
    STEP_BEFORE: 'step:before',
    BEACON: 'beacon',
    TOOLTIP: 'tooltip',
    TOOLTIP_CLOSE: 'close',
    STEP_AFTER: 'step:after',
    TOUR_END: 'tour:end',
    TOUR_STATUS: 'tour:status',
    TARGET_NOT_FOUND: 'error:target_not_found',
    ERROR: 'error',
}

const STATUS = {
    IDLE: 'idle',
    READY: 'ready',
    WAITING: 'waiting',
    RUNNING: 'running',
    PAUSED: 'paused',
    SKIPPED: 'skipped',
    FINISHED: 'finished',
    ERROR: 'error',
}

const Tooltip = ({
                     continuous,
                     index,
                     size,
                     step,
                     backProps,
                     closeProps,
                     skipProps,
                     primaryProps,
                     tooltipProps,
                 }) => (
    <TooltipBody {...tooltipProps}>
        {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
        <TooltipContent>{step.content}</TooltipContent>
        <TooltipFooter>
            <Button color="white" {...skipProps}>
                Skip the tour
            </Button>
            {index > 0 && (
                <Button color="white" variant="contained" {...backProps}>
                   Back
                </Button>
            )}
            {continuous && (
                <Button color="white" variant="contained" {...primaryProps}>
                    {index+1 === size ? 'Finish' : 'Next'}
                </Button>
            )}
            {!continuous && (
                <Button color="white" variant="contained" {...closeProps}>
                    Close
                </Button>
            )}
        </TooltipFooter>
    </TooltipBody>
);


const SbbTour = () => {
    const [run, setRun] = useState(false);
    const [index, setIndex] = useState(0);
    const [tour, setTour] = useState([]);
    const [key, setKey] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();
    const [tourKey, setTourKey] = useState('');

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type, step , size} = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
            confirmTutorial(tourKey).then( response => {
                    localStorage.setItem(tourKey, JSON.stringify(true));
                    setIndex(0);
                    setRun(false);
                })

        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            let timeout = 150;
            let nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

            if(EVENTS.TARGET_NOT_FOUND){
                timeout = 250;
                nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            }

            if(action === ACTIONS.NEXT){
                let url = null;
                if(step?.next_location){
                    url = step.next_location;
                }
                if(url){
                    timeout = 400;
                    if(index+1 < size){
                        navigate(url);
                    }else{
                        navigate(url, {state: {forceHideTutorial: true}});
                    }
                }
            }
            if(action === ACTIONS.PREV){
                let url = null;
                if(step?.prev_location){
                    url = step.prev_location;
                }
                if(url){
                    timeout = 400;
                    navigate(url, {state: {forceHideTutorial: true}});
                }
            }
            setTimeout(() => {
                setIndex(nextStepIndex);
            }, timeout);
        }
    };

    useEffect(()=>{
        try {
            let nextStep = tour[index];
            if(nextStep?.topOffset){
                setTimeout(() => {
                    let element = document.querySelector(nextStep.target);
                    if(element){
                        let headerHeight = 65;
                        let elementPosition = element?.getBoundingClientRect()?.top;
                        let offsetPosition = elementPosition + headerHeight + nextStep.topOffset;
                        window.scrollTo({
                            top: offsetPosition,
                            behavior: "smooth"
                        });
                    }
                }, 450);
            }
        }catch (error){

        }
    },[index])

    const startTour = () => {
        setKey((prevCounter) => prevCounter+1);
    }

    useEffect(()=>{
        // monitor and keep the tutorials based on what page we go to
        Object.keys(tours_locations).map((v)=>{
            if(location.pathname.indexOf(v) > 0){
                setTour(tours_locations[v]);
                setTourKey(getHideTutorialKey(v));
            }
        });
    }, [location])

    useEffect(()=>{
        if(tour?.length > 0 && tourKey){
            setIndex(0);
            setRun(true);
        }
    },[key]);

    useEffect(()=>{
        document?.addEventListener('startTour', startTour);
        return () => {
            setIndex(0);
            setRun(false);
            try {
                document.removeEventListener('startTour', startTour);
            }
            catch(err) {
                console.log(err);
            }

        }
    },[]);

    return <Joyride
                    callback={handleJoyrideCallback}
                    continuous
                    key={'joyride_unique_key_'+key}
                    run={run}
                    scrollToFirstStep
                    showProgress={false}
                    showSkipButton={true}
                    stepIndex={index}
                    steps={tour}
                    tooltipComponent={Tooltip}
                    styles={{
                        options: {
                            arrowColor: '#353D50',
                            zIndex: 2000,
                        }
                    }}
                />
};

export default SbbTour;
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/splash.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../images/splash-2.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d-404{height:100vh;margin:0;display:flex;align-items:center;justify-content:center;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___}),url(${___CSS_LOADER_URL_REPLACEMENT_1___});background-position:right bottom,left top;background-repeat:no-repeat,no-repeat;background-size:40% 40%,45% 60%}@media screen and (max-width: 959px){.d-404{background-image:unset}}`, "",{"version":3,"sources":["webpack://./src/pages/notFound/404.scss"],"names":[],"mappings":"AAGA,OACE,YAAA,CACA,QAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,gGAAA,CACA,yCAAA,CACA,qCAAA,CACA,+BAAA,CACA,qCAXF,OAYI,sBAAA,CAAA","sourcesContent":["\n\n\n.d-404{\n  height: 100vh;\n  margin: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  background-image: url('../../images/splash.png'), url('../../images/splash-2.png');\n  background-position: right bottom, left top;\n  background-repeat: no-repeat, no-repeat;\n  background-size: 40% 40%, 45% 60%;\n  @media screen and (max-width: 959px) {\n    background-image: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

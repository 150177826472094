import { takeLatest } from 'redux-saga/effects';
import * as types from './reducer';
import {getUserProfileSaga, getUserSaga} from "./handler";

export default function* watchUserApi() {
    yield takeLatest(types.GET_USER, getUserSaga);
}

export function* watchUserProfileApi() {
    yield takeLatest(types.GET_USER_PROFILE, getUserProfileSaga);
}

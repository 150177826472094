export const GET_DASH_CARDS = 'GET_DASH_CARDS';
export const GET_DASH_CARDS_SUCCESS = 'GET_DASH_CARDS_SUCCESS';
export const GET_DASH_CARDS_ERROR = 'GET_DASH_CARDS_ERROR';

export const getDashboardCards = () => {
    return {
        type: GET_DASH_CARDS,
    }
};

export default function(state = [], action) {
    const response = action.response;
    switch(action.type) {
        case GET_DASH_CARDS_SUCCESS:
            return { ...state, response };
        case GET_DASH_CARDS_ERROR:
            return { ...state, response };
        default:
            return state;
    }
}


export const upgradeAction = (e, title) => {
    let title_verbose = '';
    if(title){
        title_verbose = title
    }else{
        title_verbose = e.target.getAttribute('data-title');
    }
    const event = new CustomEvent('upgradeMember', {
        detail: {
            title: title_verbose
        }
    });
    document.dispatchEvent(event);
}

export const connectAccount = () => {
    const event = new CustomEvent('addPlatform');
    document.dispatchEvent(event);
}

export const handleTalentsRefresh = () => {
    const addNewPlatform = new Event('refreshPlatforms');
    document.dispatchEvent(addNewPlatform);
}
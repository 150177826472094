import {hasUser, setCookie} from "./cookies";
import {imageBaseUrl} from "./baseurl";

export function getUser() {
    if(localStorage.getItem('user')){
        return JSON.parse(localStorage.getItem('user'));
    }
    return null;
}

export function hasPaymentMethod(){
    return getUser() ? getUser()['has_payment_method'] : null
}

export function hasActiveSubscription(){
    return getUser() ? getUser()['active_subscription'] : null
}

export function getRole(){
    if(hasUser()){
        return getUser() ? getUser()['role'] : null;
    }
    return null;
}

export function getFullName(){
    if(hasUser()){
        return getUser() ? getUser()['first_name'] : null;
    }
    return null;
}

export function getID() {
    let user = getUser();
    if (user && user['id']) {
        return user['id'];
    }
    return null;
}

function getEmail() {
    let user = getUser();
    if (user && user['email']) {
        return user['email'];
    }
    return '';
}

export function getProfilePicture() {
    let profilePicture = getUser()?.profile_picture;
    if (profilePicture) {
        return imageBaseUrl(profilePicture);
    }
    return getUser() ? getUser()['avatar'] : null;
}

export function deletionRequest(){
    let user = getUser();
    if (user && user['delete_request']) {
        return user['delete_request_details'];
    }
    return false;
}

/***
 * The defined pages, where the User Menu is not shown
 * @type {string[]}
 */
const locationUerMenuExceptions = [
  // 'my-media-kit',
  'profile'
];

/**
 *  Function used In general on The Main Menu Structure to show/hide User menu
 * @param pathname
 * @returns {boolean}
 */
export function getUserMenuException(pathname) {
    let hideMenu = false;
    locationUerMenuExceptions.map(exception=>{
        if(pathname.indexOf(exception) > -1){
            hideMenu = true;
        }
    });
    return hideMenu;
}

export function saveUserSession(result) {
    if(result.status === 200){
        let data = result.data;
        if(data.hasOwnProperty('role')){
            for(let tour of data['user_tutorials']){
                localStorage.setItem(tour.name, JSON.stringify(tour.finished));
            }

            setCookie('role', data.role, 24*14);
            setCookie('id', data.id, 24*14);
            localStorage.setItem('user', JSON.stringify(data));
            return true
        }
    }
    return false
}
